import React, { useEffect, useState, useCallback } from "react";

// material
import {
  Grid,
  Typography,
  Checkbox,
  Stack,
  ListItemText,
  ListItem,
  ListItemIcon,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputBase,
  TableCell,
  Box,
  TextareaAutosize,
  InputAdornment,
  IconButton,
  Badge,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
} from "@mui/material";
import { TablePagination } from "@mui/material";
// components
import Page from "../components/Page";
import Autocomplete from "@mui/material/Autocomplete";
import Counter from "../components/Counter";
import MultipleSelect from "../components/MultipleSelect";

import { getCatalog } from "../utils/apiCalls";
import { getCountryCurrency } from "../utils/getCountryCurrency";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { useDispatch, useSelector } from "react-redux";
import { addNewGood } from "../store/cartSlice";
import Iconify from "../components/Iconify";
import { styled } from "@mui/material/styles";
import { haveDuplicates } from "../utils";
import { getTokenDetails } from "../utils/apiCalls";
import {useTranslation} from "../providers";

const RootStyle = styled("div")(({ theme }) => ({
  height: 24,
  zIndex: 999,
  cursor: "pointer",
  alignItems: "center",
  transition: theme.transitions.create("opacity"),
  color: theme.palette.primary.main,
}));

const StyledPage = styled(Page)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
}));

const categoryGroupId = {
  noScore: "00",
  codeCollection: "02",
  creme: "03",
  makeUp: "04",
};

export default function Goods() {
  const [catalog, setCatalog] = useState();
  const [sortType, setSortType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [products, setProducts] = useState([]);
  const [productsCategory, setProductsCategory] = useState([]);
  const [isCatalogLoading, setIsCatalogLoading] = useState(true);
  const [showProductAddBanner, setShowProductAddBanner] = useState();
  const [productsQuantity, setProductsQuantity] = useState({});
  const [filters, setFilters] = useState({});
  console.log("filters:", filters);
  const [searchFilter, setSearchFilter] = useState("");
  const {t} = useTranslation();

  //redux
  const { status } = useSelector((state) => state.carts);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  //------------------

  useEffect(() => {
    if (!catalog)
      getCatalog()
        .then((data) => {
          setCatalog(data);
          setProducts(data);
          setProductsCategory(data);
          setIsCatalogLoading();
        })
        .catch((err_data) => {
          console.log("no data", err_data);
        })
        .finally(() => setIsCatalogLoading(false));
  }, []);

  const categoryFilters = [
    ...(filters[categoryGroupId.noScore] ?? []),
    ...(filters[categoryGroupId.codeCollection] ?? []),
    ...(filters[categoryGroupId.creme] ?? []),
    ...(filters[categoryGroupId.makeUp] ?? []),
  ];

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [volumes, setVolumes] = useState("");

  const [goodsNr, setGoodsNr] = useState("");
  const [category, setCategory] = useState([]);
  const [goodsGroup, setGoodsGroup] = useState("");

  const filterCategory = useCallback(
    (groupId) => productsCategory.filter((n) => n.goods_group_id === groupId),
    [productsCategory]
  );

  const noScoreProducts = filterCategory(categoryGroupId.noScore);
  const codeCollectionProducts = filterCategory(categoryGroupId.codeCollection);
  const cremeProducts = filterCategory(categoryGroupId.creme);
  const makeUpProducts = filterCategory(categoryGroupId.makeUp);

  const allUniqueProducts = [
    ...noScoreProducts,
    ...codeCollectionProducts,
    ...cremeProducts,
    ...makeUpProducts,
  ];

  const filteredProducts = allUniqueProducts
    .filter((g) => {
      if (
        searchFilter &&
        !g.goods_name.toLowerCase().includes(searchFilter.toLowerCase())
      ) {
        return false;
      }
      if (
        categoryFilters.length > 0 &&
        !categoryFilters.includes(g.goods_name)
      ) {
        return false;
      }

      if (filters.volume && filters.volume !== g.goods_volume) {
        return false;
      }
      if (filters.number && filters.number !== g.goods_number) {
        return false;
      }
      return true;
    })
    .sort((a, b) => {
      if (sortType === "asc") {
        return a.prices[0].price < b.prices[0].price ? -1 : 1;
      }
      if (sortType === "desc") {
        return a.prices[0].price < b.prices[0].price ? 1 : -1;
      }
      if (sortType === "ascPoints") {
        return a.goods_point < b.goods_point ? -1 : 1;
      }
      if (sortType === "descPoints") {
        return a.goods_point < b.goods_point ? 1 : -1;
      }
      // else {
      //   return a.prices[0].price < b.prices[0].price ? 1 : -1;
      // }
    });

  const onGoodsNrChange = (e) => setGoodsNr(e.target.value);

  const onGoodsGroup = (e) => {
    let goodsGroupName = e.target.value;
    let goodsName = [
      ...new Set(
        products
          .filter((n) => n.goods_group_name === goodsGroupName)
          .map((n) => n.goods_name)
      ),
    ];

    setGoodsGroup(goodsGroupName);
    setCategory(goodsName);
  };

  // const onSortTypeChange = e => setSortType(e.target.value);
  const onSortTypeChange = (e) => setSortType(e.target.value);
  const { wh: isWareHouse } = getTokenDetails() ?? {};
  console.log("isWareHouse:", isWareHouse);
  const filteredCategoryValues = ({ target: { checked, value } }) => {
    setCategory(
      !category.includes(value) && checked
        ? [...category, value]
        : category.filter((n) => n !== value)
    );
  };

  const getUniqueNameFromGroup = (groupProducts) =>
    groupProducts.filter((obj, index) => {
      return (
        index ===
        groupProducts.findIndex((o) => obj.goods_name === o.goods_name)
      );
    });

  let filteredGoods = products
    .filter(
      (n) =>
        (!volumes || n.goods_volume === volumes) &&
        (!goodsNr || n.goods_number === goodsNr) &&
        (!goodsGroup || n.goods_group_name === goodsGroup) &&
        (!category.length || category.includes(n.goods_name))
    )
    .sort((a, b) => {
      if (sortType === "asc") {
        return a.prices[0].price < b.prices[0].price ? -1 : 1;
      }
      if (sortType === "desc") {
        return a.prices[0].price < b.prices[0].price ? 1 : -1;
      }
      if (sortType === "ascPoints") {
        return a.goods_point < b.goods_point ? -1 : 1;
      }
      if (sortType === "descPoints") {
        return a.goods_point < b.goods_point ? 1 : -1;
      }
    });

  const categoryFiltersAreSelected = haveDuplicates(
    Object.values(categoryGroupId),
    Object.keys(filters)
  );

  const receivedFilteredVolumes = [
    ...new Set(
      filteredGoods
        .filter(
          (n) =>
            !categoryFiltersAreSelected ||
            Object.keys(filters).includes(n.goods_group_id)
        )
        .map((n) => n.goods_volume)
    ),
  ];

  const array2 = receivedFilteredVolumes.filter((element) => element !== "");
  const array3 = receivedFilteredVolumes
    .filter((element) => element !== "")
    .sort(function (a, b) {
      return a - b;
    });

  let receivedGoodsFilteredNr = [
    ...new Set(
      filteredGoods
        .filter(
          (n) =>
            !categoryFiltersAreSelected ||
            Object.keys(filters).includes(n.goods_group_id)
        )
        .map((n) => n.goods_number)
    ),
  ];

  //array for nr sort
  const arrayNrFiltered = receivedGoodsFilteredNr
    .filter((element) => element !== "")
    .sort(function (a, b) {
      return a - b;
    });

  let receivedGoodsGroup = [
    ...new Set(productsCategory.map((n) => n.goods_group_name)),
  ];

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchFilter(query);
  };

  const currencyAll = getCountryCurrency(user.country);
  const currency = currencyAll[0];
  // const currency = getCountryCurrency(`${user.country}`.toString().slice(1));

  const handleCloseBanner = () => {
    setShowProductAddBanner(false);
  };

  const handleAddNewGood = (product) => () => {
    dispatch(
      addNewGood({ product, quantity: productsQuantity[product._id] ?? 1 })
    );
    setShowProductAddBanner(true);
    handleQuantityChange(product)(1);
  };

  const handleQuantityChange = (product) => (value) => {
    setProductsQuantity({ ...productsQuantity, [product._id]: value });
  };

  const handleSelectChange = (filterGroup) => (optionsArr) => {
    handleChangePage(null, 0);
    if (optionsArr?.length) {
      setFilters({
        ...filters,
        [filterGroup]: optionsArr.map((n) => n.goods_name),
      });
    } else {
      const newFilters = { ...filters };
      delete newFilters[filterGroup];
      setFilters(newFilters);
    }
  };

  const handleOtherFilters = (filterName, value) => {
    handleChangePage(null, 0);
    setFilters({ ...filters, [filterName]: value });
  };

  return (
    <StyledPage title="Товары">
      <Typography variant="h4" pb={1}>
        {t('FILTERS')}
      </Typography>
      <Paper sx={{ p: 3, mb: 5, zIndex: 999 }}>
        <Grid pb={{ xs: 1, sm: 4 }} container spacing={{ xs: 1, sm: 4 }}>
          <Grid item sm={6} xs={12}>
            <Typography variant="button" pb={1}>
              {t('PERFUMERY')}
            </Typography>
            <MultipleSelect
              options={getUniqueNameFromGroup(codeCollectionProducts)}
              onChange={handleSelectChange(categoryGroupId.codeCollection)}
              label="goods_name"
              value="_id"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="button" pb={1}>
              {t('MAKEUP')}
            </Typography>
            <MultipleSelect
              options={getUniqueNameFromGroup(makeUpProducts)}
              onChange={handleSelectChange(categoryGroupId.makeUp)}
              label="goods_name"
              value="_id"
            />
          </Grid>
        </Grid>
        <Grid container pb={{ xs: 1, sm: 4 }} spacing={{ xs: 1, sm: 4 }}>
          <Grid item sm={6} xs={12}>
            <Typography variant="button" pb={1}>
              {t('GOODS_2')}
            </Typography>
            <MultipleSelect
              options={getUniqueNameFromGroup(cremeProducts)}
              onChange={handleSelectChange(categoryGroupId.creme)}
              label="goods_name"
              value="_id"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="button" pb={1}>
              {t('GOODS_3')}
            </Typography>
            <MultipleSelect
              options={getUniqueNameFromGroup(noScoreProducts)}
              onChange={handleSelectChange(categoryGroupId.noScore)}
              label="goods_name"
              value="_id"
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                {t('GOODS_4')}
              </InputLabel>
              <Select
                size={"medium"}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label={t('PRICE')}
                onChange={onSortTypeChange}
                value={sortType}
              >
                <MenuItem value={"asc"}>{t('GOODS_5')}</MenuItem>
                <MenuItem value={"desc"}>{t('GOODS_6')}</MenuItem>
                <MenuItem value={"ascPoints"}>{t('GOODS_7')}</MenuItem>
                <MenuItem value={"descPoints"}>{t('GOODS_8')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Autocomplete
              size={"small"}
              id="combo-box-demo"
              width="100%"
              options={array3}
              onChange={(event, newVolume) => {
                handleOtherFilters("volume", newVolume);
              }}
              renderInput={(params) => (
                <TextField {...params} label={t('VOLUME')} variant="standard" />
              )}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Autocomplete
              size={"small"}
              id="combo-box-demo"
              options={arrayNrFiltered}
              onChange={(event, newNr) => {
                handleOtherFilters("number", newNr);
              }}
              renderInput={(params) => (
                <TextField {...params} label={t('NUMBER')} variant="standard" />
              )}
            />
          </Grid>
          {/* <Grid item sm={2} xs={6} className={"goods_no_desktop"}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                Категория
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label=" Категория"
                onChange={onGoodsGroup}
                value={goodsGroup}
              >
                <MenuItem value={""}>Сбросить</MenuItem>
                {receivedGoodsGroup.map((n) => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item sm={3} xs={6}>
            <TextField
              fullWidth
              variant="standard"
              className={"backgroundSearch"}
              id="filled-search"
              label="Название"
              type="search"
              value={searchFilter}
              onChange={handleSearch}
            />
          </Grid> */}
        </Grid>
      </Paper>
      {status === "loading" || isCatalogLoading ? (
        <CircularProgress />
      ) : (
        <>
          {filteredProducts?.length === 0 ? (
            <Typography variant="h6" pb={1}>
              {t('GOODS_9')}
            </Typography>
          ) : (
            <>
              <Typography variant="h4" pb={4}>
                {t('GOODS_10')} ({filteredProducts.length})
              </Typography>
              <Paper sx={{ zIndex: 99 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table sx={{ minWidth: 550 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('NAME')}</TableCell>
                            <TableCell align="right">{t('NUMBER')}</TableCell>
                            <TableCell align="right">{t('DISCOUNT')}</TableCell>
                            <TableCell align="right">{t('VOLUME')}</TableCell>
                            <TableCell align="right">{t('POINTS')}</TableCell>
                            <TableCell align="right">
                              {t('PRICE')}&nbsp;({currency})
                            </TableCell>
                            {!isWareHouse && (
                              <>
                                <TableCell align="right">{t('QUANTITY')}</TableCell>
                                <TableCell align="right">&nbsp;</TableCell>
                              </>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredProducts
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((product) => (
                               <TableRow
                                key={product._id}
                                className={product.prices[0].discount ? 'background_discount_row' : ''}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {product.goods_name}
                                </TableCell>
                                <TableCell align="right">
                                  {product.goods_number}
                                </TableCell>
                                <TableCell align="right">
                                  {product.prices[0].discount}
                                </TableCell>
                                <TableCell align="right">
                                  {product.goods_volume}
                                </TableCell>
                                <TableCell align="right">
                                  {product.goods_point}
                                </TableCell>
                                <TableCell align="right">
                                  {product.prices[0].price}
                                </TableCell>
                                {!isWareHouse && (
                                  <>
                                    <TableCell
                                      align="right"
                                      hidden={!isWareHouse}
                                    >
                                      <Counter
                                        value={
                                          productsQuantity[product._id] ?? 1
                                        }
                                        onChange={handleQuantityChange(product)}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      hidden={isWareHouse}
                                    >
                                      <IconButton
                                        onClick={handleAddNewGood(product)}
                                        sx={{ width: 40, height: 40 }}
                                      >
                                        <RootStyle>
                                          <Iconify
                                            icon="eva:shopping-cart-fill"
                                            sx={{
                                              width: "24px",
                                              height: "24px",
                                            }}
                                            with="24px"
                                            height="24px"
                                            // color="#637381"
                                          />
                                        </RootStyle>
                                      </IconButton>
                                    </TableCell>
                                  </>
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={filteredProducts.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableContainer>
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={showProductAddBanner}
                      autoHideDuration={4000}
                      onClose={handleCloseBanner}
                    >
                      <Alert
                        onClose={handleCloseBanner}
                        severity="success"
                        sx={{ width: "100%" }}
                      >
                        {t('GOODS_1')}
                      </Alert>
                    </Snackbar>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </>
      )}
    </StyledPage>
  );
}
