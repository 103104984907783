import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Box, Container, Typography } from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// components
import Page from "../components/Page";
import Logo from "../components/Logo";
// sections
import { LoginForm } from "../sections/auth/login";
import AuthSocial from "../sections/auth/AuthSocial";

import { getAuthCookie } from "../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  // padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login(props) {
  const navigate = useNavigate();
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  console.log("props in login", props);

  var isLoggedIn = !!getAuthCookie();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard", { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return (
    <Page title="Login">
      <RootStyle>
        {/* <HeaderStyle></HeaderStyle> */}

        {mdUp && (
          <SectionStyle>
            <Box
              top="5%"
              sx={{ mx: 'auto', width: 300, mt: "42px" }}
            >
              <Logo />
            </Box>
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 10, mb: 5 }}
              textAlign="center"
            >
              Добро пожаловать в Giter World
            </Typography>
            <img
              src="/static/illustrations/illustration_login.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle className={'enter_form_padding'}>
            <Typography variant="h4" textAlign="center" sx={{ mb: 2 }} >
              Войдите в личный кабинет
            </Typography>

            {/* <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography> */}

            {/* <AuthSocial /> */}

            <LoginForm />

            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )} */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
