import React, { useEffect, useState } from "react";
// material
import {
  Container,
  TableCell,
  Typography,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Alert,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Iconify from "../components/Iconify";
// components
import Page from "../components/Page";
import Counter from "../components/Counter";
import { getAuthCookie, myAxios } from "../utils/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import {
  changeProductQuantity,
  deleteGoods,
  fetchCarts,
  resetCart,
} from "../store/cartSlice";
import { getCountryCurrency } from "../utils/getCountryCurrency";
import {useTranslation} from "../providers";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  height: 24,
  zIndex: 999,
  cursor: "pointer",
  alignItems: "center",
  transition: theme.transitions.create("opacity"),
  color: theme.palette.primary.main,
}));

export default function ShoppingBag() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [productToDelete, setProductToDelete] = useState();
  const {t} = useTranslation();
  // const currency = getCountryCurrency(`${user.country}`.toString().slice(1));
  const currencyAll = getCountryCurrency(user.country);
  const currency = currencyAll[0];

  const dispatch = useDispatch();

  const handleClickOpen = (product) => {
    setProductToDelete(product);
    setOpen(true);
  };

  const handleClose = () => {
    setProductToDelete(undefined);
    setOpen(false);
  };

  let x = getAuthCookie();
  const headers = {
    "Access-Control-Allow-Credentials": true,
    Authorization: x,
    "Content-type": "application/json; charset=UTF-8",
  };

  useEffect(() => {
    dispatch(fetchCarts());
  }, [dispatch]);

  const carts = useSelector((state) => state.carts.carts);
  const cartsItems = carts?.items ?? [];
  const isCartEmpty = cartsItems.length === 0;

  // create order from cart
  const createFromCart = () => {
    setIsSubmitting(true);
    setShowErrorBanner(false);
    myAxios
      .post(`/order/create-from-cart/`, cartsItems, {
        headers: headers,
      })
      .then((res) => {
        if (res) {
          setShowSuccessBanner(true);
          dispatch(resetCart());
          setTimeout(() => {
            navigate(`/my-orders/${res.data.orderId}`);
          }, 4000);
        } else {
          console.log("order error");
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      })
      .catch((err) => {
        setShowErrorBanner(true);
        console.log(err);
      });
  };

  const [total, setTotal] = useState(0);
  const [totalPoint, setTotalPoint] = useState("");

  useEffect(() => {
    let sum = cartsItems.reduce((total, { count }) => count + total, 0);
    setTotal(sum);
  }, [cartsItems]);

  useEffect(() => {
    const cartItemsCopy = [...cartsItems];
    let allPoints = cartItemsCopy.reduce(
      // (total, { count, total_point }) => count * total_point + total, 0
      (total, { total_point }) => (total_point * 10 + total * 10) / 10,
      0
    );
    setTotalPoint(allPoints);
  }, [cartsItems]);

  const handleQuantityChange = (product) => (value) => {
    dispatch(
      changeProductQuantity({
        ...product,
        quantity: value > product.count ? +1 : -1,
      })
    );
  };

  return (
    <Page title="Корзина">
      <Container>
        {isCartEmpty ? (
          <Typography variant="h3">{t('EMPTY_CART')}</Typography>
        ) : (
          <>
            <Typography variant="h3" sx={{ mb: 5 }}>
              {t('CART')}
            </Typography>
            <TableContainer sx={{ mb: 5 }} component={Paper}>
              <Table sx={{ minWidth: 550 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('NAME')}</TableCell>
                    <TableCell align="right">{t('VOLUME')}</TableCell>
                    <TableCell align="right">{t('DISCOUNT')}</TableCell>
                    <TableCell align="right">{t('POINTS')}</TableCell>
                    <TableCell align="right">{t('PRICE')} / {currency}</TableCell>
                    <TableCell align="right">{t('QUANTITY')}</TableCell>
                    <TableCell align="right">&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartsItems?.map((product) => (
                    <TableRow
                      key={product.item_c_code}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography>{product.name.split(",")[0]}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography>{product.name.split(",")[1]}</Typography>
                      </TableCell>
                      <TableCell align="right">{product.discount}</TableCell>
                      {/*<TableCell align="right">{(parseInt((product.total_point * product.count)*100))/100}</TableCell>*/}
                      <TableCell align="right">{product.total_point}</TableCell>
                      <TableCell align="right">{product.total}</TableCell>
                      <TableCell align="right">
                        <Counter
                          value={product.count}
                          onChange={handleQuantityChange(product)}
                          disabledDecrement={product.count === 1}
                          editable={false}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {/* <Button
                          variant="outlined"
                          onClick={() => dispatch(deleteGoods(product))}
                        >
                          Удалить
                        </Button> */}
                        <IconButton
                          onClick={() => handleClickOpen(product)}
                          sx={{ width: 40, height: 40 }}
                        >
                          <RootStyle>
                            <Iconify
                              icon="mdi:trash"
                              sx={{
                                width: "24px",
                                height: "24px",
                              }}
                              with="24px"
                              height="24px"
                            />
                          </RootStyle>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/*<div>Всего товаров : {carts.count} </div>*/}
            <Typography variant="h4">{t('TOTAL_POINTS')} : {totalPoint} </Typography>
            <Typography variant="h4">
              {t('TOTAL_COST')} : {carts.total} {currency}
            </Typography>
            <br />
            {cartsItems.length >= 1 && (
              <LoadingButton
                size="large"
                variant="contained"
                loading={isSubmitting}
                sx={{
                  width: 300,
                }}
                onClick={() => createFromCart()}
              >
                {t('PLACE_ORDER')}
              </LoadingButton>
            )}
          </>
        )}
        {showSuccessBanner && (
          <Alert sx={{ mt: 3 }} severity="success">
            {t('CART_1')}
          </Alert>
        )}
        {showErrorBanner && (
          <Alert sx={{ mt: 3 }} severity="error">
            {t('CART_2')}!
          </Alert>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('CART_3')}
          </DialogTitle>
          <DialogActions sx={{ p: 3 }}>
            <Button
              variant="contained"
              onClick={() => {
                dispatch(deleteGoods(productToDelete));
                setOpen(false);
              }}
            >
              {t('DELETE')}
            </Button>
            <Button variant="outlined" onClick={handleClose} autoFocus>
              {t('CANCEL')}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
