import { Typography } from "@mui/material";
import { getStatusParams } from "../utils";

export default function OrderStatus({ status, ...other }) {
  const params = getStatusParams(status);

  return (
    <Typography variant="body" fontWeight={600} color={params.color} {...other}>
      {params.label}
    </Typography>
  );
}
