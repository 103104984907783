import { useRef, useState } from "react";
// material
import { alpha } from "@mui/material/styles";
import { Box, MenuItem, Stack, IconButton, Typography } from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
import { useTranslation } from "../../providers";

// ----------------------------------------------------------------------
// https://atuin.ru/blog/flagi-stran-mira-na-svg/ флаги тут

// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { setLanguage, language } = useTranslation();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        handleClose();
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        bgcolor: (theme) =>
                            alpha(
                                theme.palette.primary.main,
                                theme.palette.action.focusOpacity
                            ),
                    }),
                }}
            >
                <Typography variant="h6">{language}</Typography>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 120,
                    "& .MuiMenuItem-root": {
                        px: 1,
                        typography: "body2",
                        borderRadius: 0.75,
                    },
                }}
            >
                <Stack spacing={0.75}>
                    <MenuItem
                        selected={language === "RU"}
                        onClick={() => handleLanguageChange("RU")}
                    >
                        <Box
                            component="img"
                            alt={"RU"}
                            src={"/static/icons/ru_3x4.svg"}
                            sx={{ width: 28, mr: 2 }}
                        />
                        RU
                    </MenuItem>
                    <MenuItem
                        selected={language === "RO"}
                        onClick={() => handleLanguageChange("RO")}
                    >
                        <Box
                            component="img"
                            alt={"RO"}
                            src={"/static/icons/md_3x4.svg"}
                            sx={{ width: 28, mr: 2 }}
                        />
                        RO
                    </MenuItem>
                    <MenuItem
                        selected={language === "EN"}
                        onClick={() => handleLanguageChange("EN")}
                    >
                        <Box
                            component="img"
                            alt={"EN"}
                            src={"/static/icons/ic_flag_en.svg"}
                            sx={{ width: 28, mr: 2 }}
                        />
                        EN
                    </MenuItem>
                    <MenuItem
                        selected={language === "UA"}
                        onClick={() => handleLanguageChange("UA")}
                    >
                        <Box
                            component="img"
                            alt={"UA"}
                            src={"/static/icons/ua.svg"}
                            sx={{ width: 28, mr: 2 }}
                        />
                        UA
                    </MenuItem>
                    <MenuItem
                        selected={language === "IT"}
                        onClick={() => handleLanguageChange("IT")}
                    >
                        <Box
                            component="img"
                            alt={"IT"}
                            src={"/static/icons/it.svg"}
                            sx={{ width: 28, mr: 2 }}
                        />
                        IT
                    </MenuItem>
                </Stack>
            </MenuPopover>
        </>
    );
}
