import React, { useState } from "react";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableSortLabel,
  CircularProgress, TextField, Grid,
} from "@mui/material";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { getNetwork } from "../utils/apiCalls";
import TableHead from "@mui/material/TableHead";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "../providers";


export default function MyNetwork() {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [network, setNetwork] = useState("");

  const [networkForSort, setNetworkForSort] = useState([]);

  const [searchFilterRank, setSearchFilterRank] = useState("");

  const [searchFilterName, setSearchFilterName] = useState("");

  const [searchFilterId, setSearchFilterId] = useState("");

  const {t} = useTranslation();

  const navigate = useNavigate();

  if (network === "")
    getNetwork()
      .then((data) => {
        console.log("network data", data);
        setNetwork(data);
        setNetworkForSort(data);
      })
      .catch((err_data) => {
        console.log("no data", err_data);
        // TODO: Make allert!
      });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [orderDirection, setOrderDirection] = useState("asc");

  const [active, setActive] = useState("");


  const handleSortRequestRank = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setNetworkForSort(sortArrayRank(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayRank = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...networkForSort].sort((a, b) =>
        a.rank < b.rank ? 1 : b.rank < a.rank ? -1 : 0
      );
    } else {
      return [...networkForSort].sort((a, b) =>
        a.rank > b.rank ? 1 : b.rank > a.rank ? -1 : 0
      );
    }
  };

  const handleSortRequestName = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setNetworkForSort(sortArrayName(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayName = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...networkForSort].sort((a, b) =>
        a.name < b.name ? 1 : b.name < a.name ? -1 : 0
      );
    } else {
      return [...networkForSort].sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    }
  };

  const handleSortRequestId = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setNetworkForSort(sortArrayId(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayId = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...networkForSort].sort((a, b) =>
          a._id < b._id ? 1 : b._id < a._id ? -1 : 0
      );
    } else {
      return [...networkForSort].sort((a, b) =>
          a._id > b._id ? 1 : b._id > a._id ? -1 : 0
      );
    }
  };

  const handleSortRequestLO = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setNetworkForSort(sortArrayLO(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayLO = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...networkForSort].sort((a, b) =>
          a.lo < b.lo ? 1 : b.lo < a.lo ? -1 : 0
      );
    } else {
      return [...networkForSort].sort((a, b) =>
          a.lo > b.lo ? 1 : b.lo > a.lo ? -1 : 0
      );
    }
  };

  const handleSortRequestGO = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setNetworkForSort(sortArrayGO(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayGO = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...networkForSort].sort((a, b) =>
          a.go < b.go ? 1 : b.go < a.go ? -1 : 0
      );
    } else {
      return [...networkForSort].sort((a, b) =>
          a.go > b.go ? 1 : b.go > a.go ? -1 : 0
      );
    }
  };

  const handleSortRequestNGO = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setNetworkForSort(sortArrayNGO(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayNGO = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...networkForSort].sort((a, b) =>
          a.ngo < b.ngo ? 1 : b.ngo < a.ngo ? -1 : 0
      );
    } else {
      return [...networkForSort].sort((a, b) =>
          a.ngo > b.ngo ? 1 : b.ngo > a.ngo ? -1 : 0
      );
    }
  };


  const handleSortRequestOLG = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setNetworkForSort(sortArrayOLG(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayOLG = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...networkForSort].sort((a, b) =>
          a.olg < b.olg ? 1 : b.olg < a.olg ? -1 : 0
      );
    } else {
      return [...networkForSort].sort((a, b) =>
          a.olg > b.olg ? 1 : b.olg > a.olg ? -1 : 0
      );
    }
  };

  const handleSortRequestDataSigned = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setNetworkForSort(sortArrayDataSigned(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayDataSigned = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...networkForSort].sort((a, b) =>
        a.signed < b.signed ? 1 : b.signed < a.signed ? -1 : 0
      );
    } else {
      return [...networkForSort].sort((a, b) =>
        a.signed > b.signed ? 1 : b.signed > a.signed ? -1 : 0
      );
    }
  };

  const handleSortRequestEmail = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setNetworkForSort(sortArrayDataEmail(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayDataEmail = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...networkForSort].sort((a, b) =>
          a.email < b.email ? 1 : b.email < a.email ? -1 : 0
      );
    } else {
      return [...networkForSort].sort((a, b) =>
          a.email > b.email ? 1 : b.email > a.email ? -1 : 0
      );
    }
  };

  const handleSortRequestPhone = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setNetworkForSort(sortArrayDataPhone(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayDataPhone = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...networkForSort].sort((a, b) =>
          a.phone < b.phone ? 1 : b.phone < a.phone ? -1 : 0
      );
    } else {
      return [...networkForSort].sort((a, b) =>
          a.phone > b.phone ? 1 : b.phone > a.phone ? -1 : 0
      );
    }
  };

  /////////search by id, name, rank
  const handleSearchId = (event) => {
    const query = event.target.value;
    setSearchFilterId(query);
  };

  const handleSearchName = (event) => {
    const query = event.target.value;
    setSearchFilterName(query);
  };

  const handleSearchRank = (event) => {
    const query = event.target.value;
    setSearchFilterRank(query);
  };

  const networkForSort2 = networkForSort
      .filter((g) => {
        if (
            searchFilterName &&
            !g.name.toLowerCase().includes(searchFilterName.toLowerCase())
        ) {
          return false;
        }
        if (
            searchFilterId &&
            !g._id.toLowerCase().includes(searchFilterId.toLowerCase())
        ) {
          return false;
        }
        if (
            searchFilterRank &&
            !g.rank.toLowerCase().includes(searchFilterRank.toLowerCase())
        ) {
          return false;
        }
        return true;
      })

  // const isNetworkEmpty = networkForSort.length === 0;
  // console.log("networkForSort:", networkForSort);

  if (!networkForSort) {
    return <CircularProgress align="center" />;
  } else {
    return (
      <Page title="Моя сеть">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {t('MY_NETWORK')}
              <div style={{ fontWeight: "bolder", fontSize: "14px" }}>
                {t('CURRENT_MONTH')}
              </div>
            </Typography>
            {/*<Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>*/}
            {/*  New User*/}
            {/*</Button>*/}
          </Stack>
          <Grid container spacing={5} style={{paddingTop: "0px", marginTop: "-75px"}}>
          <Grid item sm={3} xs={6}>
          <TextField
              fullWidth
              variant="standard"
              className={"backgroundSearch"}
              id="filled-search"
              label={t('PRN')}
              type="search"
              value={searchFilterId}
              onChange={handleSearchId}
          />

          </Grid>
            <Grid item sm={3} xs={6}>
              <TextField
                  fullWidth
                  variant="standard"
                  className={"backgroundSearch"}
                  id="filled-search"
                  label={t('FULL_NAME')}
                  type="search"
                  value={searchFilterName}
                  onChange={handleSearchName}
              />
            </Grid>
              <Grid item sm={3} xs={6}>
                <TextField
                    fullWidth
                    variant="standard"
                    className={"backgroundSearch"}
                    id="filled-search"
                    label={t('RANG')}
                    type="search"
                    value={searchFilterRank}
                    onChange={handleSearchRank}
                />
              </Grid>
          </Grid>
          <br/>
        </Container>
          <Card>
            <Scrollbar>
              {/*<TableContainer sx={{ minWidth: 1100 }}>*/}
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key={1}
                        id={"1"}
                        onClick={handleSortRequestId}
                        align="right"
                      >
                        <TableSortLabel
                          key={1}
                          id={"1"}
                          active={active === "1"}
                          direction={orderDirection}
                        >
                          {t('PRN')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        key={2}
                        id={"2"}
                        onClick={handleSortRequestName}
                        align="right"
                      >
                        <TableSortLabel
                          key={2}
                          id={"2"}
                          active={active === "2"}
                          direction={orderDirection}
                        >
                          {t('FULL_NAME')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                          key={3}
                          id={"3"}
                          onClick={handleSortRequestEmail}
                          align="right"
                      >
                        <TableSortLabel
                            key={3}
                            id={"3"}
                            active={active === "3"}
                            direction={orderDirection}
                        >
                          {t('EMAIL')}
                        </TableSortLabel>
                        {/*<span*/}
                        {/*    className="material-symbols-outlined"*/}
                        {/*    style={{*/}
                        {/*      top: "4px",*/}
                        {/*      position: "relative",*/}
                        {/*      fontSize: "1em",*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*  unfold_more*/}
                        {/*</span>*/}
                      </TableCell>
                      <TableCell
                          key={4}
                          id={"4"}
                          onClick={handleSortRequestPhone}
                          align="right"
                      >
                        <TableSortLabel
                            key={4}
                            id={"4"}
                            active={active === "4"}
                            direction={orderDirection}
                        >
                          {t('PHONE')}
                        </TableSortLabel>
                        {/*<span*/}
                        {/*    className="material-symbols-outlined"*/}
                        {/*    style={{*/}
                        {/*      top: "4px",*/}
                        {/*      position: "relative",*/}
                        {/*      fontSize: "1em",*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*  unfold_more*/}
                        {/*</span>*/}
                      </TableCell>
                      <TableCell
                        key={5}
                        id={"5"}
                        onClick={handleSortRequestRank}
                        align="right"
                      >
                        <TableSortLabel
                          key={5}
                          id={"5"}
                          active={active === "5"}
                          direction={orderDirection}
                        >
                          {t('RANG')}
                        </TableSortLabel>
                        {/*<span*/}
                        {/*  className="material-symbols-outlined"*/}
                        {/*  style={{*/}
                        {/*    top: "4px",*/}
                        {/*    position: "relative",*/}
                        {/*    fontSize: "1em",*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  unfold_more*/}
                        {/*</span>*/}
                      </TableCell>

                      <TableCell
                        key={6}
                        id={"6"}
                        onClick={handleSortRequestLO}
                        align="right"
                      >
                        <TableSortLabel
                          key={6}
                          id={"6"}
                          active={active === "6"}
                          direction={orderDirection}
                        >
                          {t('PV')}
                        </TableSortLabel>
                        {/*<span*/}
                        {/*  className="material-symbols-outlined"*/}
                        {/*  style={{*/}
                        {/*    top: "4px",*/}
                        {/*    position: "relative",*/}
                        {/*    fontSize: "1em",*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  unfold_more*/}
                        {/*</span>*/}
                      </TableCell>
                      <TableCell
                        key={7}
                        id={"7"}
                        onClick={handleSortRequestGO}
                        align="right"
                      >
                        <TableSortLabel
                          key={7}
                          id={"7"}
                          active={active === "7"}
                          direction={orderDirection}
                        >
                          {t('GV')}
                        </TableSortLabel>
                        {/*<span*/}
                        {/*  className="material-symbols-outlined"*/}
                        {/*  style={{*/}
                        {/*    top: "4px",*/}
                        {/*    position: "relative",*/}
                        {/*    fontSize: "1em",*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  unfold_more*/}
                        {/*</span>*/}
                      </TableCell>
                      <TableCell
                        key={8}
                        id={"8"}
                        onClick={handleSortRequestNGO}
                        align="right"
                      >
                        <TableSortLabel
                          key={8}
                          id={"8"}
                          active={active === "8"}
                          direction={orderDirection}
                        >
                          {t('CGV')}
                        </TableSortLabel>
                        {/*<span*/}
                        {/*  className="material-symbols-outlined"*/}
                        {/*  style={{*/}
                        {/*    top: "4px",*/}
                        {/*    position: "relative",*/}
                        {/*    fontSize: "1em",*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  unfold_more*/}
                        {/*</span>*/}
                      </TableCell>
                      <TableCell
                        key={9}
                        id={"9"}
                        onClick={handleSortRequestOLG}
                        align="right"
                      >
                        <TableSortLabel
                          key={9}
                          id={"9"}
                          active={active === "9"}
                          direction={orderDirection}
                        >
                          {t('PGV')}
                        </TableSortLabel>
                        {/*<span*/}
                        {/*  className="material-symbols-outlined"*/}
                        {/*  style={{*/}
                        {/*    top: "4px",*/}
                        {/*    position: "relative",*/}
                        {/*    fontSize: "1em",*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  unfold_more*/}
                        {/*</span>*/}
                      </TableCell>
                      <TableCell
                        key={10}
                        id={"10"}
                        onClick={handleSortRequestDataSigned}
                        align="right"
                      >
                        <TableSortLabel
                          key={10}
                          id={"10"}
                          active={active === "10"}
                          direction={orderDirection}
                        >
                          {t('SIGNING_DATE')}
                        </TableSortLabel>
                        {/*<span*/}
                        {/*  className="material-symbols-outlined"*/}
                        {/*  style={{*/}
                        {/*    top: "4px",*/}
                        {/*    position: "relative",*/}
                        {/*    fontSize: "1em",*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  unfold_more*/}
                        {/*</span>*/}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {networkForSort2
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => (
                        <TableRow
                          hover
                          key={item._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                          onClick={() => navigate(`${item._id}`)}
                        >
                          <TableCell align="right">{item._id}</TableCell>
                          <TableCell align="right">{item.name}</TableCell>
                          <TableCell align="right">{item.email}</TableCell>
                          <TableCell align="right">{item.phone}</TableCell>
                          <TableCell align="right">{item.rank}</TableCell>
                          <TableCell align="right">{item.lo}</TableCell>
                          <TableCell align="right">{item.go}</TableCell>
                          <TableCell align="right">{item.ngo}</TableCell>
                          <TableCell align="right">{item.olg}</TableCell>
                          <TableCell align="right">
                            {" "}
                            {item.signed !== null && item.signed.split("T")[0]}
                          </TableCell>
                          {/*<TableCell align="right">*/}
                          {/*  <UserMoreMenu />*/}
                          {/*</TableCell>*/}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={networkForSort2.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>

      </Page>
    );
  }
}
