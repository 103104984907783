import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as Yup from "yup";
import { useFormik } from "formik";

import ActionsWrapper from "./ActionsWrapper";
import {
  postWarehouseAction,
  warehouseCancelOrder,
} from "../../utils/ClientApi";
import {useTranslation} from "../../providers";

const validationSchema = Yup.object({
  reason: Yup.string().required("Обязательное поле"),
});

export default function WhDeclineForm({ actionWrapper, children, orderId }) {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const actions = [
    {
      label: `${t('ACCEPT')}`,
      handler: () => postWarehouseAction(orderId, "pending"),

      variant: "contained",
    },
    {
      label: `${t('REJECTED')}`,
      handler: () => handleClickOpen(),
    },
  ];

  const { handleChange, submitForm, errors } = useFormik({
    initialValues: { reason: "" },
    validationSchema,
    onSubmit: async (values) => {
      actionWrapper(() => warehouseCancelOrder(orderId, values.reason));
      setOpen(false);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('ORDER_REJECTED')}</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label={t('REASON')}
            name="reason"
            type="text"
            fullWidth
            variant="standard"
            error={!!errors.reason}
            onChange={handleChange}
          />
          <FormHelperText error>{errors.reason}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('CANCEL')}</Button>
          <Button onClick={submitForm}>{t('REJECTED')}</Button>
        </DialogActions>
      </Dialog>
      <ActionsWrapper
        actions={actions}
        orderId={orderId}
        actionWrapper={actionWrapper}
      />
    </div>
  );
}
