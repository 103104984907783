import ActionsWrapper from "./ActionsWrapper";

import OrderTable from "./OrderTable";

export default function ClientPartialOrder({ order, actionWrapper, actions }) {
  return (
    <div>
      <OrderTable
        partialOrder={{ ...order, items: order.new_order.items }}
        order={order}
      />
      <br />
      <ActionsWrapper
        actionWrapper={actionWrapper}
        actions={actions}
        orderId={order._id}
      />
    </div>
  );
}
