import axios from "axios";
import Cookies from "js-cookie";

import { HOST } from "./constants";

export const basePath = HOST;

export const myAxios = axios.create({
  baseURL: basePath,
  timeout: 40000,
  // headers: { "X-Custom-Header": "foobar" },
});

myAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // if (error.response) {
    //   if (error.response.status === 401) {
    //     deleteAuthCookie();
    //     window.location.href = "/login";
    //   }
    // }

    return Promise.reject(error);
  }
);

myAxios.interceptors.request.use(
  function (config) {
    console.log("config:", config);
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getAuthCookie = () => {
  return Cookies.get("Authorization");
};

export const setAuthCookie = (token) => {
  Cookies.set("Authorization", `Bearer ${token}`);
};

export const deleteAuthCookie = () => {
  Cookies.remove("Authorization");
};

export const getTokenDetails = () => {
  const token = getAuthCookie();
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return {
      ...JSON.parse(window.atob(base64)),
      // wh: true,
    };
  }
  return null;
};

// POST login
export function login(login, password) {
  const bodyFormData = new FormData();
  bodyFormData.append("username", login);
  bodyFormData.append("password", password);
  const response = myAxios
    .post(`/login/`, bodyFormData)
    .then((response) => response.data);
  return response;
}

// GET get my data by id
export function getMeById() {
  if (!getAuthCookie()) {
    return null;
  }
  const response = myAxios
    .get(`/cabinet/me/`, {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
    })
    .then((response) => response.data);
  return response;
}

// GET get my target data
export function getTarget() {
  const response = myAxios
    .get(`/cabinet/my-target/`, {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
      // withCredentials: true
    })
    .then((response) => response.data);
  return response;
}

// GET get my network
export function getNetwork() {
  const response = myAxios
    .get(`/cabinet/my-network/`, {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
      // withCredentials: true
    })
    .then((response) => response.data);
  return response;
}

//Get new partners

export function getNewPartners() {
    return myAxios
        .get(`/warehouse/my-news/`, {
            headers: {
                "Access-Control-Allow-Credentials": true,
                Authorization: getAuthCookie(),
            },
            // withCredentials: true
        })
        .then((response) => response.data);
}

//get newPartners by id warehouse/order/
export function getNewPartnersId(id) {
    return myAxios
        .get(`/warehouse/my-news/` + `?id=` + id, {
            headers: {
                "Access-Control-Allow-Credentials": true,
                Authorization: getAuthCookie(),
            },
            // withCredentials: true
        })
        .then((response) => response.data);
}
///
export  function rejectPartner(newPartnerId) {
    return myAxios
        .post(`/users/partner-registration/reject`, null,{
            headers: {
                "Access-Control-Allow-Credentials": true,
                Authorization: getAuthCookie(),
            },
            params: {
                id: newPartnerId,
            },
            timeout: 6000
        })
        .then((response) => response.data);
}
//

export  function postLanguage(lang) {
    let langLower = lang.toLowerCase();
    return myAxios
        .post(`/users/lang/`  + langLower + `/`, null,{
            headers: {
                "Access-Control-Allow-Credentials": true,
                Authorization: getAuthCookie(),
            },
            timeout: 6000
        })
        .then((response) => response.data);
}
//

//get lang
export function getLanguage() {
    return myAxios
        .get(`/users/me/`, {
            headers: {
                "Access-Control-Allow-Credentials": true,
                Authorization: getAuthCookie(),
            },
        })
        .then((response) => response.data)
        // .then((response) => console.log('dataLM', response.data))
}
///

export  function submitPartnerOneC(newPartnerId) {
    return myAxios
        .post(`/users/partner-registration/one-c`, null,{
            headers: {
                "Access-Control-Allow-Credentials": true,
                Authorization: getAuthCookie(),
            },
            params: {
                id: newPartnerId,
            },
            timeout: 6000
        })
        .then((response) => response.data);
}

//

export function getNetworkById(id) {
  const response = myAxios
    .get(`/cabinet/my-network/` + id, {
      // const response = axios.get(`${basePath}cabinet/my-network/`, {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
      // withCredentials: true
    })
    .then((response) => response.data);
  return response;
}

//GET get my catalog
export function getCatalog() {
  const response = myAxios
    .get(`/cabinet/catalog/`, {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
      // withCredentials: true
    })
    .then((response) => response.data);
  return response;
}

//GET get my shopping bag goods
export function getShoppingGoods() {
  return myAxios
    .get(`/order/cart/`, {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
      // withCredentials: true
    })
    .then((response) => response.data)
    .catch(function (error) {
      console.log("Throw exception: ", error);
    });
}

//GET lnr
export function requestPassword(lnr)  {
    return myAxios
        .post(`/users/reset-password/`, null,{
            headers: {
                "Access-Control-Allow-Credentials": true,
            },
            params: {
                lnr: lnr,
            },
            timeout: 6000
        })
        .then((response) => response.data);
}