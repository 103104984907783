const defaultHost = "https://giterbackend.azurewebsites.net/api/";

export const HOST =
  (process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_HOST
    : process.env.REACT_APP_HOST) || defaultHost;

export const STATUS = {
  NEW: "new",
  USER_CANCEL: "user_cancel",
  WAREHOUSE_PENDING: "warehouse_pending",
  WAREHOUSE_DECLINED: "warehouse_declined",
  WAREHOUSE_CHECKED_ACCEPTED: "warehouse_checked_accepted",
  WAREHOUSE_CHECKED_DECLINED: "warehouse_checked_declined",
  WAREHOUSE_CHECKED_CHANGE: "warehouse_checked_change",
  USER_ACCEPTED: "user_accepted",
  USER_DECLINED: "user_declined",
  READY: "ready",
  READY_FOR_DELIVERY: "ready_for_delivery",
  READY_FOR_PICKUP: "pickup",
  DELIVERY_IN_PROGRESS: "delivery_in_progress",
  DONE: "done",
};
