import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LinearProgress } from "@mui/material";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import { fetchCarts } from "./store/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "./store/authSlice";
import palette from "./theme/palette";

// ----------------------------------------------------------------------

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { authStatus, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (authStatus === "rejected") {
      navigate("/login");
    }
  }, [authStatus, navigate]);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(fetchCarts());
    }
  }, [user, dispatch]);

  return (
    <ThemeProvider>
      {authStatus !== "fulfilled" && location.pathname !== "/login" ? (
        <LinearProgress color="primary" />
      ) : (
        <>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </>
      )}
    </ThemeProvider>
  );
}
