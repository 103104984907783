import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {dictionary} from "../data/dictionary";
import {getLanguage, postLanguage} from "../utils/apiCalls";

const LanguageContext = createContext();

export const useTranslation = () => {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }

  return context;
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('RU');
  console.log(language, 'languageProvider')

  const t = useCallback(
    (key) => {
      return dictionary[language][key] || key;
    },
    [language],
  );

    const handleSetLanguage = useCallback(
        (lang) => {
            postLanguage(lang)
                .then(response => response.data)
                .then((data) => {
                    console.log("Success:", data);
                    setLanguage(lang); // Update the language state only if the post was successful
                })
                .catch((error) => {
                    console.error("error:", error);
                });
        },
        [setLanguage],
    );

    useEffect(() => {
        getLanguage()
            .then((data) => {
                setLanguage(data.data.lang.toUpperCase()); // Directly set the language state from the server response
            })
            .catch((error) => {
                console.log("no data", error);
            });
    }, []);

    const value = useMemo(
        () => ({
            language,
            setLanguage: handleSetLanguage,
            t,
            dictionary: dictionary[language],
        }),
        [language, t, handleSetLanguage],
    );

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};
