import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// components
import Iconify from "../../components/Iconify";

import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import CartPopover from "./CartPopover";
import { getTokenDetails } from "../../../src/utils/apiCalls";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
// const APPBAR_MOBILE = 64;
const APPBAR_MOBILE = 50;
// const APPBAR_DESKTOP = 92;
const APPBAR_DESKTOP = 57;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: "white",
  borderBottom: `solid 1px ${theme.palette.divider}`,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { wh: isWareHouse } = getTokenDetails() ?? {};
  console.log("isWareHouse:", isWareHouse);
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        {/*<Searchbar />*/}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <LanguagePopover />
          {!isWareHouse &&
            <CartPopover />
          }
          {/* <CartPopover />
          <NotificationsPopover /> */}
          <AccountPopover />
          {/* {window.location.pathname !== "/shopping-bag" && <CartPopover />} */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
