import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Promotions from "./pages/Blog";
import MyOrders from "./pages/MyOrders";
import MyInvitations from "./pages/MyInvitations";
import Newbies from "./pages/Newbies";
import MyNetwork from "./pages/MyNetwork";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import Register from "./pages/Register";
import ShoppingBag from "./pages/ShoppingBag";
import Goods from "./pages/Goods";
import DashboardApp from "./pages/DashboardApp";
import MyOrdersItem from "./pages/MyOrdersItem";
import MyNetworksItem from "./pages/MyNetworksItem";
import { getTokenDetails } from "./utils/apiCalls";
import NewPartners from "./pages/NewPartners";
import NewPartnersItem from "./pages/NewPartnersItem";

// ----------------------------------------------------------------------

export default function Router(props) {
  const { wh: isWareHouse } = getTokenDetails() ?? {};

  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout partnerId={props.partnerId} />,
      children: [{ path: "", element: <DashboardApp /> }],
    },
    {
      path: "/invitations",
      element: <DashboardLayout />,
      children: [{ path: "", element: <MyInvitations /> }],
    },
    {
      path: "/newbies",
      element: <DashboardLayout />,
      children: [{ path: "", element: <Newbies /> }],
    },
    {
      path: "/network",
      element: <DashboardLayout />,
      children: [
        { path: "", element: <MyNetwork partnerId={props.partnerId} /> },
      ],
    },
    {
      path: "/network/:id",
      element: <DashboardLayout />,
      children: [{ path: "", element: <MyNetworksItem /> }],
    },
    {
      path: "/newpartners",
      element: <DashboardLayout />,
      children: [{ path: "", element: <NewPartners /> }],
    },
    {
      path: "/newpartners/:id",
      element: <DashboardLayout />,
      children: [{ path: "", element: <NewPartnersItem /> }],
    },

    {
      path: "/products",
      element: <DashboardLayout />,
      children: [{ path: "", element: <Goods /> }],
    },
    {
      path: "/shopping-bag",
      element: <DashboardLayout />,
      children: [{ path: "", element: <ShoppingBag /> }],
    },
    {
      path: "/my-orders",
      element: <DashboardLayout />,
      children: [{ path: "", element: <MyOrders /> }],
    },
    {
      path: "/my-orders/:id",
      element: <DashboardLayout />,
      children: [{ path: "", element: <MyOrdersItem /> }],
    },

    {
      path: "/blog",
      element: <DashboardLayout />,
      children: [{ path: "", element: <Promotions /> }],
    },

    {
      path: "/wh-orders",
      element: <DashboardLayout />,
      children: [{ path: "", element: <MyOrders /> }],
    },
    {
      path: "/wh-orders/:id",
      element: <DashboardLayout />,
      children: [{ path: "", element: <MyOrdersItem /> }],
    },

    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/login" /> },
        {
          path: "login",
          element: (
            <Login
              partnerId={props.partnerId}
              setPartnerId={props.setPartnerId}
            />
          ),
        },
        { path: "register", element: <Register /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
