import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import {Box, Link} from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  // const logo = (
  //   <Box sx={{ width: 40, height: 40, ...sx }}>
  //     <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  //     width="40pt" height="40pt" viewBox="0 0 630.000000 630.000000"
  //     preserveAspectRatio="xMidYMid meet">
  //     <metadata>
  //     Created by potrace 1.16, written by Peter Selinger 2001-2019
  //     </metadata>
  //     <g transform="translate(0.000000,630.000000) scale(0.100000,-0.100000)"
  //     fill="#000000" stroke="none">
  //     <path d="M2705 5829 c-39 -86 -139 -151 -394 -258 -236 -99 -348 -180 -403
  //     -294 -25 -50 -48 -154 -48 -211 0 -42 29 -29 57 24 54 103 146 166 385 266 83
  //     35 153 62 155 61 1 -2 -61 -32 -140 -67 -78 -35 -170 -79 -204 -97 -91 -50
  //     -198 -151 -180 -170 3 -2 58 24 124 60 65 35 171 90 236 123 109 54 217 132
  //     217 155 0 6 -10 7 -22 4 -18 -5 -16 -2 7 13 40 26 68 58 93 107 12 22 21 35
  //     22 30 0 -18 -72 -149 -99 -178 -47 -51 -113 -92 -315 -196 -335 -171 -488
  //     -287 -552 -417 -28 -57 -29 -65 -34 -242 -4 -162 -1 -206 16 -189 2 2 13 51
  //     24 108 16 84 27 114 56 157 70 101 218 199 543 359 215 106 255 137 301 234
  //     29 61 45 133 55 251 5 48 10 65 19 62 16 -6 60 57 80 112 7 22 19 73 25 112
  //     20 127 10 158 -24 81z"/>
  //     <path d="M3560 5844 c0 -43 21 -165 36 -208 20 -55 64 -118 80 -112 9 3 14
  //     -17 19 -72 14 -164 53 -270 124 -341 26 -26 102 -70 242 -139 326 -162 462
  //     -252 533 -354 29 -43 40 -73 56 -157 11 -57 22 -106 24 -108 17 -17 20 27 16
  //     189 -5 177 -6 185 -34 242 -64 130 -217 246 -552 417 -202 104 -268 145 -315
  //     196 -27 29 -99 160 -99 178 1 5 10 -8 22 -30 25 -49 53 -81 93 -107 23 -15 25
  //     -18 8 -13 -13 3 -23 2 -23 -4 0 -23 108 -101 217 -155 65 -33 171 -88 236
  //     -123 66 -36 121 -62 124 -60 18 19 -89 120 -180 170 -34 18 -126 62 -204 97
  //     -79 35 -141 65 -140 67 2 1 72 -26 155 -61 239 -100 331 -163 385 -266 28 -53
  //     57 -66 57 -24 0 57 -23 161 -48 211 -55 114 -167 195 -403 294 -255 107 -355
  //     172 -394 258 -21 47 -35 53 -35 15z"/>
  //     <path d="M1790 5254 c-16 -42 -134 -157 -288 -281 -294 -238 -363 -354 -326
  //     -552 8 -47 18 -103 21 -123 3 -22 11 -38 19 -38 14 0 19 14 30 75 17 97 110
  //     214 277 348 48 39 94 86 107 109 13 22 50 72 83 111 65 75 64 72 92 261 18
  //     118 18 116 5 116 -6 0 -15 -12 -20 -26z"/>
  //     <path d="M4480 5271 c0 -5 9 -68 20 -141 11 -73 20 -136 20 -140 0 -5 29 -42
  //     64 -83 35 -42 74 -94 87 -116 12 -22 59 -70 106 -108 167 -134 260 -251 277
  //     -348 11 -61 16 -75 30 -75 8 0 16 16 19 38 3 20 13 76 21 123 37 198 -32 315
  //     -327 552 -148 119 -280 249 -292 286 -6 21 -25 30 -25 12z"/>
  //     <path d="M1975 4811 c-63 -43 -70 -52 -82 -97 -7 -27 -13 -57 -13 -66 0 -20
  //     26 -48 46 -48 27 0 115 93 146 153 57 113 17 137 -97 58z"/>
  //     <path d="M4207 4853 c-25 -25 32 -141 102 -206 54 -50 71 -56 95 -31 19 19 19
  //     27 4 94 -11 49 -16 55 -84 101 -69 46 -102 58 -117 42z"/>
  //     <path d="M1814 4713 c-49 -40 -100 -97 -122 -138 -13 -26 -42 -178 -35 -185 6
  //     -6 102 99 143 156 39 55 72 140 68 177 l-3 30 -51 -40z"/>
  //     <path d="M4430 4721 c0 -40 33 -123 70 -175 42 -58 137 -162 144 -156 3 3 -3
  //     44 -12 92 -20 96 -48 145 -121 210 -70 61 -81 65 -81 29z"/>
  //     <path d="M1505 4651 c-117 -100 -192 -183 -220 -243 -24 -51 -31 -78 -20 -78
  //     14 0 309 310 321 337 31 72 18 70 -81 -16z"/>
  //     <path d="M4700 4710 c0 -35 45 -92 179 -227 83 -84 153 -153 156 -153 11 0 4
  //     27 -20 79 -28 58 -130 170 -236 257 -68 55 -79 62 -79 44z"/>
  //     <path d="M3094 4690 c-11 -4 -33 -22 -47 -40 l-27 -32 0 -1379 c0 -1265 1
  //     -1382 16 -1405 50 -75 189 -72 229 6 13 25 15 195 15 1407 0 1517 5 1405 -60
  //     1438 -31 16 -92 19 -126 5z"/>
  //     <path d="M2685 4636 c-255 -68 -449 -180 -636 -366 -147 -147 -249 -297 -320
  //     -475 -228 -567 -96 -1187 341 -1608 151 -146 297 -238 485 -306 178 -64 275
  //     -70 337 -21 60 46 58 26 58 758 l0 669 -22 33 c-13 18 -36 43 -51 54 -28 20
  //     -38 21 -415 21 -371 0 -388 -1 -414 -20 -50 -37 -66 -122 -34 -183 30 -58 53
  //     -62 378 -62 215 0 297 -3 306 -12 16 -16 18 -998 2 -998 -27 0 -176 66 -250
  //     110 -319 192 -510 488 -559 869 -19 146 -8 289 34 450 92 355 363 659 698 786
  //     37 14 72 25 77 25 6 0 10 -156 12 -395 l3 -395 26 -26 c34 -34 102 -43 147
  //     -20 64 33 63 26 60 555 -3 467 -4 480 -24 507 -55 74 -108 85 -239 50z"/>
  //     <path d="M3445 4646 c-37 -16 -70 -52 -84 -89 -8 -20 -11 -429 -11 -1319 0
  //     -1171 2 -1293 16 -1324 37 -78 106 -102 219 -79 460 95 861 464 1020 938 73
  //     218 94 484 56 706 -98 574 -501 1022 -1041 1157 -108 27 -133 29 -175 10z
  //     m261 -325 c174 -73 358 -216 470 -365 327 -434 320 -1039 -16 -1461 -120 -151
  //     -300 -281 -488 -351 -34 -13 -67 -24 -72 -24 -7 0 -10 397 -10 1120 0 798 3
  //     1120 11 1120 6 0 53 -18 105 -39z"/>
  //     <path d="M1436 4478 c-155 -155 -220 -237 -271 -339 -54 -106 -68 -168 -62
  //     -273 4 -84 9 -102 70 -246 85 -202 116 -219 61 -33 -21 72 -25 102 -21 164 7
  //     112 35 162 196 346 76 87 147 174 157 193 14 28 19 66 24 178 3 79 1 146 -3
  //     149 -5 3 -72 -60 -151 -139z"/>
  //     <path d="M4709 4616 c-2 -3 -2 -69 1 -148 5 -112 10 -150 24 -178 10 -19 81
  //     -106 157 -193 161 -184 189 -234 196 -346 4 -62 0 -92 -21 -164 -55 -186 -24
  //     -169 61 33 61 144 66 162 70 246 8 136 -35 258 -139 399 -78 105 -335 364
  //     -349 351z"/>
  //     <path d="M1066 4408 c-10 -83 -52 -198 -143 -398 -124 -270 -156 -371 -157
  //     -490 -1 -120 18 -165 110 -261 66 -67 101 -88 90 -51 -22 73 -28 122 -23 185
  //     6 78 50 228 99 335 23 50 33 90 39 157 4 50 19 126 33 170 14 44 26 92 26 107
  //     0 27 -36 250 -46 281 -10 32 -21 18 -28 -35z"/>
  //     <path d="M5206 4443 c-10 -31 -46 -254 -46 -281 0 -15 12 -63 26 -107 14 -44
  //     29 -120 33 -170 6 -67 16 -107 39 -157 49 -107 93 -257 99 -335 5 -63 -1 -112
  //     -23 -185 -11 -37 24 -16 90 51 92 96 111 141 110 261 -1 119 -33 220 -157 490
  //     -91 200 -133 315 -143 398 -7 53 -18 67 -28 35z"/>
  //     <path d="M1410 4058 l-71 -83 5 -49 c3 -31 13 -58 26 -70 19 -19 22 -20 48 -6
  //     41 23 116 175 116 237 1 45 -1 48 -26 51 -22 3 -37 -10 -98 -80z"/>
  //     <path d="M4763 4124 c-18 -47 69 -245 120 -274 24 -14 28 -13 47 6 13 12 23
  //     39 26 70 l5 49 -71 83 c-53 62 -77 82 -96 82 -13 0 -27 -7 -31 -16z"/>
  //     <path d="M1282 3927 c-54 -65 -72 -220 -38 -329 9 -32 21 -58 26 -58 15 0 50
  //     180 50 260 0 84 -8 140 -20 140 -4 0 -12 -6 -18 -13z"/>
  //     <path d="M4986 3909 c-12 -63 -6 -189 15 -278 11 -50 25 -91 29 -91 18 0 44
  //     116 44 195 -1 68 -5 89 -30 140 -16 32 -34 61 -40 63 -6 1 -14 -11 -18 -29z"/>
  //     <path d="M1071 3768 c-5 -13 -22 -57 -39 -98 -41 -103 -70 -204 -78 -272 -7
  //     -58 1 -128 15 -128 4 0 13 24 20 53 7 28 35 130 62 225 27 95 49 185 49 202 0
  //     37 -17 48 -29 18z"/>
  //     <path d="M5207 3784 c-15 -15 -5 -71 42 -236 27 -95 55 -197 62 -225 7 -29 16
  //     -53 20 -53 14 0 22 70 15 128 -9 73 -29 142 -78 272 -43 111 -50 125 -61 114z"/>
  //     <path d="M1105 3688 c-96 -308 -124 -432 -131 -578 -4 -69 -2 -139 5 -175 16
  //     -88 73 -197 124 -238 38 -31 282 -187 293 -187 2 0 4 7 4 16 0 9 -38 53 -85
  //     99 -162 157 -177 252 -99 630 30 145 28 161 -38 323 -48 115 -63 139 -73 110z"/>
  //     <path d="M5123 3578 c-67 -162 -69 -178 -39 -323 78 -378 63 -473 -99 -630
  //     -47 -46 -85 -90 -85 -99 0 -9 2 -16 4 -16 11 0 255 156 293 187 51 41 108 150
  //     124 238 14 77 6 258 -16 355 -16 70 -67 256 -98 359 -8 25 -18 46 -24 48 -6 2
  //     -33 -52 -60 -119z"/>
  //     <path d="M720 3297 c0 -2 22 -53 49 -113 62 -138 78 -204 101 -417 51 -453 94
  //     -577 226 -649 46 -25 243 -83 252 -74 10 10 -22 48 -56 65 -104 53 -156 182
  //     -192 471 -8 67 -17 95 -42 135 -65 102 -98 190 -98 265 0 79 -18 111 -123 218
  //     -87 88 -117 113 -117 99z"/>
  //     <path d="M5463 3198 c-105 -107 -123 -139 -123 -218 0 -75 -33 -163 -98 -265
  //     -25 -40 -34 -68 -42 -135 -36 -289 -88 -418 -192 -471 -34 -17 -66 -55 -56
  //     -65 9 -9 206 49 252 74 132 72 175 196 226 649 23 213 39 279 101 417 27 60
  //     49 111 49 113 0 14 -30 -11 -117 -99z"/>
  //     <path d="M1247 3248 c-11 -9 -21 -44 -31 -103 -16 -101 -8 -136 42 -175 52
  //     -42 82 -15 82 73 0 87 -42 217 -69 217 -4 0 -15 -6 -24 -12z"/>
  //     <path d="M5010 3246 c-22 -27 -50 -140 -50 -203 0 -88 30 -115 82 -73 50 39
  //     58 74 42 175 -10 59 -20 94 -31 103 -22 16 -28 15 -43 -2z"/>
  //     <path d="M1183 2914 c7 -113 42 -187 128 -270 37 -34 64 -54 67 -46 11 33 -86
  //     267 -150 360 -43 63 -52 55 -45 -44z"/>
  //     <path d="M5072 2958 c-64 -93 -161 -327 -150 -360 3 -8 30 12 67 46 86 83 121
  //     157 128 270 7 99 -2 107 -45 44z"/>
  //     <path d="M1105 2643 c2 -16 9 -66 16 -113 16 -122 46 -244 70 -293 24 -48 87
  //     -117 106 -117 18 0 17 2 -16 68 -40 80 -77 198 -102 327 -14 75 -28 117 -42
  //     133 -27 29 -37 28 -32 -5z"/>
  //     <path d="M5163 2648 c-14 -16 -28 -58 -42 -133 -25 -129 -62 -247 -102 -327
  //     -33 -66 -34 -68 -16 -68 19 0 82 69 106 117 24 49 54 171 70 293 7 47 14 97
  //     16 113 5 33 -5 34 -32 5z"/>
  //     <path d="M1176 2588 c15 -80 46 -208 65 -268 26 -78 110 -251 168 -342 79
  //     -125 200 -213 327 -237 52 -10 281 -17 290 -9 13 14 -45 42 -121 59 -48 11
  //     -103 28 -123 38 -108 56 -245 270 -326 509 -19 57 -46 118 -60 137 -24 31
  //     -193 145 -216 145 -5 0 -7 -14 -4 -32z"/>
  //     <path d="M5018 2564 c-48 -30 -100 -70 -114 -89 -14 -19 -41 -80 -60 -137 -81
  //     -239 -218 -453 -326 -509 -20 -10 -75 -27 -123 -38 -76 -17 -134 -45 -121 -59
  //     9 -8 238 -1 290 9 127 24 248 112 327 237 100 159 170 323 208 492 30 137 32
  //     150 18 149 -6 0 -51 -25 -99 -55z"/>
  //     <path d="M1524 2275 c-8 -32 45 -148 78 -168 34 -23 95 -32 113 -17 36 30 1
  //     96 -89 170 -56 47 -93 52 -102 15z"/>
  //     <path d="M4674 2260 c-121 -99 -141 -194 -37 -175 59 10 82 31 115 101 53 114
  //     14 150 -78 74z"/>
  //     <path d="M1600 2059 c0 -12 49 -86 97 -145 38 -48 109 -89 178 -104 28 -5 60
  //     -12 73 -15 38 -9 25 18 -40 84 -76 77 -127 112 -223 157 -82 38 -85 39 -85 23z"/>
  //     <path d="M4595 2026 c-91 -45 -160 -97 -228 -172 -42 -47 -47 -67 -14 -59 12
  //     3 44 10 72 15 101 22 159 67 243 192 31 46 39 68 25 68 -5 0 -48 -20 -98 -44z"/>
  //     <path d="M1110 2010 c0 -5 13 -16 29 -25 48 -25 126 -111 203 -224 179 -265
  //     278 -375 383 -427 73 -35 126 -39 256 -20 59 9 109 16 113 16 3 0 6 6 6 14 0
  //     13 -55 38 -115 51 -89 19 -188 112 -318 297 -30 41 -67 79 -98 98 -27 18 -78
  //     63 -112 101 l-62 69 -140 30 c-162 35 -145 32 -145 20z"/>
  //     <path d="M5035 1989 l-130 -29 -62 -69 c-34 -38 -85 -83 -112 -101 -31 -19
  //     -68 -57 -98 -98 -130 -185 -229 -278 -318 -297 -60 -13 -115 -38 -115 -51 0
  //     -8 3 -14 6 -14 4 0 54 -7 113 -16 130 -19 183 -15 256 20 105 52 204 162 383
  //     427 77 113 155 199 203 224 29 16 40 36 17 34 -7 -1 -71 -14 -143 -30z"/>
  //     <path d="M2095 1741 c-9 -29 29 -78 91 -120 52 -34 61 -36 116 -20 42 11 60
  //     47 38 74 -21 26 -137 76 -189 82 -44 5 -50 3 -56 -16z"/>
  //     <path d="M4081 1740 c-136 -49 -173 -114 -80 -140 51 -15 61 -13 113 21 95 63
  //     124 139 53 139 -18 -1 -56 -10 -86 -20z"/>
  //     <path d="M1706 1698 c13 -22 86 -111 128 -157 50 -54 142 -115 204 -136 26 -8
  //     73 -37 106 -64 98 -82 343 -191 428 -191 29 0 30 1 23 37 -17 90 -45 121 -205
  //     228 -69 46 -150 107 -180 135 -155 144 -194 160 -386 160 -97 0 -124 -3 -118
  //     -12z"/>
  //     <path d="M4287 1690 c-57 -18 -92 -43 -197 -140 -30 -28 -102 -82 -160 -120
  //     -173 -114 -208 -152 -225 -243 -7 -36 -6 -37 23 -37 85 0 330 109 428 191 33
  //     27 80 56 106 64 95 33 183 104 285 230 64 80 69 75 -74 74 -96 0 -143 -5 -186
  //     -19z"/>
  //     <path d="M2240 1549 c0 -32 293 -219 344 -219 22 0 20 25 -5 70 -27 48 -135
  //     111 -239 139 -85 24 -100 25 -100 10z"/>
  //     <path d="M3949 1537 c-103 -31 -201 -90 -228 -137 -25 -45 -27 -70 -5 -70 51
  //     0 344 187 344 219 0 16 -25 13 -111 -12z"/>
  //     <path d="M2710 1408 c0 -7 42 -37 93 -65 l93 -53 225 -1 c123 0 232 -3 241 -7
  //     15 -6 118 -156 118 -172 0 -3 -18 -31 -40 -64 -22 -32 -40 -67 -40 -78 0 -10
  //     27 -72 60 -138 33 -66 60 -131 60 -145 0 -15 -20 -74 -45 -132 -25 -58 -45
  //     -109 -45 -114 0 -6 34 -9 78 -7 l77 3 3 479 c1 345 -1 483 -9 492 -16 20 -869
  //     21 -869 2z"/>
  //     <path d="M2710 884 c0 -359 3 -455 12 -452 7 3 38 48 68 100 47 81 60 115 84
  //     219 16 68 35 136 42 151 15 29 147 132 190 148 61 23 65 11 15 -37 -48 -48
  //     -48 -49 -85 -198 -21 -82 -44 -163 -53 -180 -8 -16 -55 -66 -104 -110 -49 -44
  //     -89 -83 -89 -88 0 -4 132 -6 293 -5 l292 3 13 75 c7 41 10 88 7 104 -3 16 -32
  //     78 -65 137 -60 107 -69 143 -50 179 6 11 40 52 75 92 36 41 65 82 65 94 0 45
  //     -19 49 -234 46 -110 -2 -216 1 -233 7 -18 5 -77 46 -132 90 -54 45 -101 81
  //     -105 81 -3 0 -6 -205 -6 -456z"/>
  //     <path d="M1940 1274 c-19 -2 -62 -9 -95 -14 -141 -25 -147 -31 -47 -49 92 -17
  //     172 -50 344 -143 167 -89 255 -128 295 -128 72 0 153 73 157 142 l1 31 -100
  //     23 c-129 29 -162 39 -241 75 -112 50 -223 72 -314 63z"/>
  //     <path d="M4227 1269 c-32 -5 -97 -25 -145 -45 -118 -49 -160 -62 -277 -89
  //     l-100 -22 1 -31 c4 -69 85 -142 157 -142 40 0 128 39 295 128 172 93 252 126
  //     345 143 37 7 67 16 67 21 0 4 -2 8 -4 8 -2 0 -39 6 -82 14 -113 20 -191 25
  //     -257 15z"/>
  //     </g>
  //     </svg>
  //   </Box>
  // );

  const logo = (
    <Box
      component="img"
      src="/static/logos/giter-world-logo.png"
      // sx={{ width: 300, height: "auto", ...sx }}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  // return <RouterLink to="/">{logo}</RouterLink>;
  return <Link href="https://giter.world/">{logo}</Link>
}
