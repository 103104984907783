// component
import Iconify from "../../components/Iconify";
import { getTokenDetails } from "../../utils/apiCalls";
import {useTranslation} from "../../providers";

// ----------------------------------------------------------------------
export default function NavConfig() {
  const { wh: isWareHouse } = getTokenDetails() ?? {};
  console.log("isWareHouse:", isWareHouse);
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

  const {t} = useTranslation();

  var navConfig = [
    {
      title: `${t('MAIN')}`,
      path: "/dashboard",
      icon: getIcon("eva:pie-chart-2-fill"),
    },
    {
      title: `${t('MY_NETWORK')}`,
      path: "/network",
      icon: getIcon("el:group"),
    },
    {
      title: `${t('PRODUCTS')}`,
      path: "/products",
      icon: getIcon("eva:shopping-bag-fill"),
    },
  ];

  if (isWareHouse) {
    navConfig.push(
      {
        title: `${t('ORDERS_WH')}`,
        path: "/wh-orders",
        icon: getIcon("eva:car-fill"),
      },
        {
          title: `${t('NEWERS')}`,
          path: "/newpartners",
          // icon: getIcon("mdi:person-multiple"),
          icon: getIcon("mdi:person"),
        }
    )
  } else {
    navConfig.push(
      {
        title: `${t('CART')}`,
        path: "/shopping-bag",
        icon: getIcon("eva:shopping-cart-fill"),
      },
      {
        title: `${t('ORDERS')}`,
        path: "/my-orders",
        icon: getIcon("eva:car-fill"),
      }
    )
  }
  
  navConfig.push({
    title: `${t('PROMOTIONS_RATINGS')}`,
    path: "/blog",
    icon: getIcon("eva:file-text-fill"),
  })

  // console.log(navConfig);

  return navConfig;
}

// export default NavConfig;
