import { useState, useRef, useEffect } from "react";
// @mui
import {
  Box,
  Badge,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";

import React from "react";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCountryCurrency } from "../../utils/getCountryCurrency";
import {useTranslation} from "../../providers";

// const productPrice = productPrice.reduce(getSum, 0);
// function getSum(total, num) {
//     return total+ num;
// }

const RootStyle = styled("div")(({ theme }) => ({
  zIndex: 999,
  cursor: "pointer",
  alignItems: "center",
  transition: theme.transitions.create("opacity"),
  "&:hover": { opacity: 0.72 },
}));

export default function CartPopover() {
  const anchorRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const carts = useSelector((state) => state.carts.carts);
  const cartsItems = carts?.items ?? [];
  const isCartEmpty = cartsItems.length === 0;
  const currencyAll = getCountryCurrency(user.country);
  const currency = currencyAll[0];
  // const currency = getCountryCurrency(`${user.country}`.toString().slice(1));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [total, setTotal] = useState(0);
  const [totalPoint, setTotalPoint] = useState(0);

  //number of items in cart
  useEffect(() => {
    let summ = [...cartsItems].reduce((total, { count }) => count + total, 0);
    setTotal(summ);
  }, [cartsItems]);

  // sum of items points
  useEffect(() => {
    const cartItemsCopy = [...cartsItems];
    let sumPoints = cartItemsCopy.reduce(
      (total, { total_point }) => (total_point * 10 + total * 10) / 10,
      0
    );
    setTotalPoint(sumPoints);
  }, [cartsItems]);

  const handleButtonClick = () => {
    navigate("/shopping-bag");
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <RootStyle>
          <Badge badgeContent={total} color="error">
            <Iconify
              icon="eva:shopping-cart-fill"
              width={24}
              height={24}
              color="#637381"
            />
          </Badge>
        </RootStyle>
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 380, p: 0, mt: 1.5, ml: 0.75, backgroundColor: "white" }}
      >
        {isCartEmpty ? (
          <Typography p={3} variant="h5">
            {t('EMPTY_CART')}
          </Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Scrollbar sx={{ height: { xs: 340, sm: 360 } }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">{t('QUANTITY_HALF')}</TableCell>
                      <TableCell align="right">{t('PRICE')}</TableCell>
                      <TableCell align="right">{t('POINTS')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cartsItems?.map((item) => (
                      <TableRow
                        key={item.item_c_code}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          <span style={{ fontWeight: "inherit" }}>
                            {item.name.split(",")[0]}
                            <br />
                            {t('VOLUME')}: {item.name.split(",")[1]}
                          </span>
                        </TableCell>
                        <TableCell align="left">{item.count}</TableCell>
                        {/*<TableCell align="left">{item.price * item.count}</TableCell>*/}
                        <TableCell align="left">{item.total}</TableCell>
                        {/*<TableCell align="left">{(parseInt((item.point * item.count)*100))/100}</TableCell>*/}
                        <TableCell align="left">{item.total_point}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Box sx={{ p: 2 }}>
              <Typography variant="h6">
                {t('TOTAL_COST')} : {carts.total} {currency}
              </Typography>
              <Typography variant="h6">{t('TOTAL_POINTS')} : {totalPoint}</Typography>
              <Typography variant="h6">{t('TOTAL_ITEMS')} : {total} </Typography>
              <Button
                sx={{ mx: "auto", width: "100%", mt: 2 }}
                variant="contained"
                onClick={handleButtonClick}
              >
                {t('GO_TO_CART')}
              </Button>
            </Box>
          </>
        )}
      </MenuPopover>
    </>
  );
}
