import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import useResponsive from "../hooks/useResponsive";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: "10%",
  left: 0,
  lineHeight: 0,
  width: "100%",
  // position: "absolute",
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(10, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const smUp = useResponsive("down", "md");
  return (
    <>
      {smUp && (
        <HeaderStyle>
          <Logo />
        </HeaderStyle>
      )}
      <Outlet />
    </>
  );
}
