import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  login,
  getMeById,
  deleteAuthCookie,
  setAuthCookie,
  getAuthCookie,
} from "../utils/apiCalls";

export const userLogin = createAsyncThunk(
  "auth/login",
  async function ({ email, password }, { rejectWithValue, dispatch }) {
    try {
      const authData = await login(email, password);
      console.log("authData:", authData);
      setAuthCookie(authData.access_token);
      console.log(getAuthCookie());
      dispatch(getMe());
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getMe = createAsyncThunk(
  "auth/getMe",
  async function (_, { rejectWithValue }) {
    try {
      const userData = await getMeById();
      return userData;
    } catch (error) {
      deleteAuthCookie();
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState = {
  user: undefined,
  loginStatus: "idle",
  authStatus: "idle",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => initialState,
    setUserData(state, action) {
      state.user = action.payload;
    },
  },

  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loginStatus = "pending";
    },
    [userLogin.fulfilled]: (state) => {
      state.loginStatus = "fulfilled";
    },
    [userLogin.rejected]: (state, action) => {
      state.loginStatus = "error";
      state.error = action.payload?.detail ?? null;
    },
    [getMe.pending]: (state) => {
      state.authStatus = "pending";
    },
    [getMe.fulfilled]: (state, action) => {
      state.authStatus = "fulfilled";
      state.user = action.payload;
    },
    [getMe.rejected]: (state, action) => {
      state.authStatus = "rejected";
      state.user = undefined;
    },
  },
});

export default authSlice.reducer;
