import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {
    getNewPartnersId, postLanguage,
    rejectPartner,
    submitPartnerOneC
} from "../utils/apiCalls";
import {Button, Card, Container, Grid, Stack, TextField, Typography} from "@mui/material";
import Page from "../components/Page";
import {styled} from "@mui/material/styles";
import {useTranslation} from "../providers";

const NewPartnersItem = () => {
    const params = useParams();
    const [newPartnersById, setNewPartnersById] = useState();
    const [newPartnersByIdItems, setNewPartnersByIdItems] = useState([]);
    const {t} = useTranslation();
    console.log(params.id);
    const newPartnerId = newPartnersByIdItems.id


    const StyledForm = styled('div')`
        background-color: white;
        border: 1px dashed ${({theme}) => theme.palette.grey['50032']};
        border-radius: 8px;
        padding: 24px;
        width: 93%;
    `;


    useEffect(() => {
        getNewPartnersId(params.id)
            .then((data) => {
                setNewPartnersById(data);
                setNewPartnersByIdItems(data?.data);
            })
            .catch((err_data) => {
                console.log("no data", err_data);
            });
    }, []);

    const handleSubmitRejected = evt => {
        evt.preventDefault();
        rejectPartner(newPartnerId)
            .then(response => response.data)
            .then((data) => console.log("Success:", (data)))
            .catch(error => console.error("Error:", error));
    }

    const submitOneC = evt => {
        evt.preventDefault();
        submitPartnerOneC(newPartnerId)
            .then(response => response.data)
            .then(data => console.log("Success:", (data)))
            .catch(error => console.error("Error:", error));
    }


    return (
        <>
            <Page>
                <Container>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={5}
                    >
                    </Stack>
                    <br/>
                    <div style={{padding: '15px', textAlign: 'right'}}>
                    </div>
                    <Typography variant="h4"> Id. - {newPartnersByIdItems.id}</Typography>
                    <br/>

                    <div style={{textAlign: 'left'}}>
                        {t('FULL_NAME')} : <strong>{newPartnersByIdItems?.full_name}</strong>
                    </div>
                    <div style={{textAlign: 'left'}}> {t('EMAIL')} :&nbsp;{newPartnersByIdItems.email}</div>
                    <div style={{textAlign: 'left'}}> {t('PHONE')} :&nbsp;{newPartnersByIdItems.phone}</div>
                    <br/>

                    <br/>
                    <StyledForm>
                        <Grid container spacing={1}>
                            <Grid item md={3} xs={11}>
                                <br/>
                                <br/>
                                <TextField
                                    type="text"
                                    label="Country"
                                    defaultValue={newPartnersByIdItems.country}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <br/>
                                <br/>
                                <TextField
                                    label="Distributor"
                                    defaultValue={newPartnersByIdItems.distributor}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <br/>
                                <br/>
                                <TextField
                                    label="Birthday"
                                    defaultValue={newPartnersByIdItems?.birthday?.split("T")[0]}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <br/>
                                <br/>
                                {!newPartnersByIdItems.warehouse ? <br/> :
                                    <TextField
                                        label="warehouse"
                                        defaultValue={newPartnersByIdItems.warehouse}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                }
                            </Grid>
                            <Grid item md={3} xs={11}>
                                <br/>
                                <br/>
                                <TextField
                                    type="text"
                                    label="City"
                                    defaultValue={newPartnersByIdItems.city}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <br/>
                                <br/>
                                <TextField
                                    label="Franchise"
                                    defaultValue={newPartnersByIdItems.franchise}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <br/>
                                <br/>
                                <TextField
                                    label="Referral"
                                    defaultValue={newPartnersByIdItems.referral}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} xs={11}>
                                <h2 style={{textAlign: 'center'}}>{t('MESSAGES')}</h2>
                                <br/>
                                <Grid container spacing={2} style={{
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderRadius: '8px',
                                    borderColor: 'rgba(145, 158, 171, 0.32)',
                                    height: '400px',
                                    overflowY: 'auto'
                                }}>
                                    {newPartnersByIdItems?.comment?.map((item, index) => <Grid item xs={11} key={index}>
                                        <div>{item?.commentator}</div>
                                        <div>{item.date}</div>
                                        <div style={{
                                            background: '#919eab1f',
                                            borderRadius: '8px',
                                            padding: '7px'
                                        }}>{item?.comment}</div>
                                        <br/>
                                    </Grid>)}
                                    {newPartnersByIdItems?.comment?.map((item, index) => <Grid item xs={11} key={index}>
                                        <div>{item?.commentator}</div>
                                        <div>{item.date}</div>
                                        <div style={{
                                            background: '#919eab1f',
                                            borderRadius: '8px',
                                            padding: '7px'
                                        }}>{item?.comment}</div>
                                        <br/>
                                    </Grid>)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledForm>
                    <br/>
                    {newPartnersByIdItems?.status === 'sendedToOneC'
                        ? <Button variant="contained"
                                  style={{backgroundColor: "#3caea3", marginRight: '20px'}}>{t('SENDTOONEC')}</Button>
                        : <div>
                        {newPartnersByIdItems?.status === 'rejected'
                            ? <div/>
                            : <Button variant="contained" style={{backgroundColor: "#3caea3", marginRight: '20px'}}
                                      onClick={submitOneC}> {t('SENDTOONEC')}</Button>
                        }
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {newPartnersByIdItems?.status === 'rejected'
                            ? <Button variant="contained" color='error'>{t('REJECTED')}</Button>
                            : <Button variant="contained"
                                      onClick={handleSubmitRejected}>{t('TOBEREJECTED')}</Button>
                        }
                        </div>
                    }
                </Container>
            </Page>
        </>
    )
};
export default NewPartnersItem;
