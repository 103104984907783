import {Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {useState} from "react";
import {useTranslation} from "../../providers";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import {clientCancelOrder} from "../../utils/ClientApi";


export default function ClDecline({ orderId }) {

    const { t } = useTranslation();
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);

    // Function to handle dialog open and close
    const handleCancelDialogOpen = (orderId) => {
        setSelectedOrderId(orderId);
        setOpenCancelDialog(true);
    };

    const handleCancelDialogClose = () => {
        setOpenCancelDialog(false);
        setSelectedOrderId(null);
    };

    const confirmCancelOrder = () => {
        clientCancelOrder(orderId)
            .then(() => {
                handleCancelDialogClose();
                // Refresh the page after canceling the order
                window.location.reload(); // Forces a page reload
            })
            .catch((error) => {
                console.error('Failed to cancel order:', error);
            });
    };

  return (
    <div>
      <Typography>
       <Button onClick={handleCancelDialogOpen} variant={'contained'}>{t('CANCEL_ORDER')}</Button>
      </Typography>
        <Dialog open={openCancelDialog} onClose={handleCancelDialogClose}>
            <DialogTitle>{t('CANCEL_ORDER2')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('ARE_YOU_SURE_CANCEL_ORDER')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelDialogClose} color="primary">
                    {t('NO')}
                </Button>
                <Button onClick={confirmCancelOrder} color="primary" autoFocus>
                    {t('YES')}
                </Button>
            </DialogActions>
        </Dialog>
    </div>
  );
}
