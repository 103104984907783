import { myAxios } from "./apiCalls";
import { getAuthCookie } from "./apiCalls";
import { HOST } from "./constants";

const basePath = HOST;

// GET if user is logged in response true
export function isAuthClient() {
  const response = myAxios
    .get(`/auth`, { withCredentials: true })
    .then((response) => response);
  return response;
}

// POST add new my shiping point
export function addShippingPoint(data) {
  const response = myAxios.post(`/add-shipping-point`, data, {
    withCredentials: true,
  });
  return response;
}

// GET get all my shiping points
export function getShippingPoint() {
  const response = myAxios
    .get(`/get-all-shipping-points`, { withCredentials: true })
    .then((response) => response.data);
  return response;
}

// GET get all my shiping points
export function getPrises(data) {
  const response = myAxios
    .get(`/calculate-order`, {
      params: {
        cargo_type: data.cargoType,
        city_sender_id: data.citySenderId,
        city_recipient_id: data.cityRecipientId,
        city_sender_title: data.citySenderTitle,
        city_recipient_title: data.cityRecipientTitle,
        weight: data.weight,
        length: data.length,
        width: data.width,
        height: data.height,
        seatAmount: data.seatAmount,
        from_door: data.deliveryMethodFrom.fromDoor,
        from_office: data.deliveryMethodFrom.fromOffice,
        to_door: data.deliveryMethodTo.toDoor,
        to_office: data.deliveryMethodTo.toOffice,
        to_postomat: data.deliveryMethodTo.toPostomat,
      },
      withCredentials: true,
    })
    .then((response) => response.data);
  return response;
}

// POST create new delivery order
export function createNewDeliveryOrder(data) {
  const response = myAxios
    .post(`${basePath}create-new-order`, data, { withCredentials: true })
    .then((response) => response.data);
  return response;
}

// GET get all my orders
export function getAllMyOrders() {
  // const response = myAxios.get(`${basePath}orders`, { withCredentials: true })
  const response = myAxios
    .get(`${basePath}order/list/`, {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
      // withCredentials: true
    })
    .then((response) => response.data);
  return response;
}

// GET get all my orders
export function getAllWhOrders() {
  // const response = myAxios.get(`${basePath}orders`, { withCredentials: true })
  const response = myAxios
    .get(`${basePath}warehouse/orders-list/`, {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
      // withCredentials: true
    })
    .then((response) => response.data);
  return response;
}

// get order by id
export function getOrderById(id) {
  const response = myAxios
    .get(`/order/` + id + "/", {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
      // withCredentials: true
    })
    .then((response) => response.data);
  return response;
}

// get order by id
export function getWhOrderById(id) {
  const response = myAxios
    .get(`/warehouse/order/` + id + "/", {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
      // withCredentials: true
    })
    .then((response) => response.data);
  return response;
}

// GET get order by id
// export function getOrderById(orderId) {
//     const response = myAxios.get(`${basePath}order`, { params: {order_id: orderId}, withCredentials: true })
//     .then(response => response.data)
//     return response;
// }

// GET get all my delivery companies
export function getDeliveryCompanies() {
  const response = myAxios
    .get(`${basePath}get-all-delivery-companies`, { withCredentials: true })
    .then((response) => response.data);
  return response;
}

// POST add my delivery companies
export function addDeliveryCompanies(data) {
  const response = myAxios
    .post(`${basePath}add-delivery-company`, data, { withCredentials: true })
    .then((response) => response.data);
  return response;
}

// get Promotions
export function getPromotions() {
  const response = myAxios
    .get(`${basePath}cabinet/promotions/`, {
      headers: {
        "Access-Control-Allow-Credentials": true,
        Authorization: getAuthCookie(),
      },
      // withCredentials: true
    })
    .then((response) => response.data);
  return response;
}

export function postWarehouseAction(orderId, action, params) {
  let body = null;
  if (action === "ready") {
    body = params;
  }
  if (action === "checked-change") {
    const items = params.items.map((item) => ({
      ...item,
      total: item.count * item.price,
    }));

    body = {
      items,
    };
  }
  let query = "";
  if (params?.reason) {
    query = "?reason=" + params.reason;
  }

  return myAxios.post(`/warehouse/order/${orderId}/${action}/` + query, body, {
    headers: {
      Authorization: getAuthCookie(),
    },
  });
}

export function warehouseCancelOrder(orderId, reason) {
  return myAxios.post(
    `/warehouse/order/${orderId}/cancel/?reason=${reason}`,
    null,
    {
      headers: {
        Authorization: getAuthCookie(),
      },
    }
  );
}

export function clientCancelOrder(orderId) {
  return myAxios.post(`/order/cancel/${orderId}/`, null, {
    headers: {
      Authorization: getAuthCookie(),
    },
  });
}

export function postClientAction(orderId, action, params) {
  let query = "";
  if (action === "ready-delivery" && params?.address) {
    query = `?address=${params.address}`;
  }
  return myAxios.post(`/order/${orderId}/${action}/` + query, null, {
    headers: {
      Authorization: getAuthCookie(),
    },
  });
}

export function postClientActionPickup(orderId, action) {
  console.log('action', action)
  return myAxios.post(`/order/${orderId}/${action}/` , null, {
    headers: {
      Authorization: getAuthCookie(),
    },
  });
}
