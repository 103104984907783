import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function ActionsWrapper({ actions, orderId, actionWrapper }) {
  console.log("orderId:", orderId);
  if (!actions?.length) return null;

  return (
    <Box sx={{ mb: 2, width: 300 }}>
      <div>
        {actions.map((action) => {
          return (
              <>
                  <Button
                      key={action.label}
                      variant={action.variant}
                      onClick={() =>
                          actionWrapper
                              ? actionWrapper(() => action.handler(orderId))
                              : action.handler()
                      }
                      fullWidth
                      sx={{mt: 1, mr: 1, w: "100%"}}
                  >
                      {action.label}
                  </Button>
                  <div className={'inform_stepper'}>{!action?.inform ? '' :
                      <InfoOutlinedIcon/>}&nbsp;{action?.inform}</div>
              </>
          );
        })}
      </div>
    </Box>
  );
}
