import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrderById, getWhOrderById } from "../utils/ClientApi";
import {
  getAuthCookie,
  getTokenDetails,
} from "../utils/apiCalls";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { myAxios } from "../utils/apiCalls";
import { useSelector } from "react-redux";
import { getCountryCurrency } from "../utils/getCountryCurrency";
import OrderStatus from "../components/OrderStatus";
import OrderStepper from "../components/order-stepper/OrderStepper";
import {useTranslation} from "../providers";

const MyOrdersItem = () => {
  const params = useParams();
  const [orderByIdItems, setOrderByIdItems] = useState([]);
  const [orderById, setOrderById] = useState("");
  const [deleteSuccess, setDeleteSuccess] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const { wh: isWareHouse } = getTokenDetails() ?? {};
  const { user } = useSelector((state) => state.auth);
  const {t} = useTranslation();

  // const currency = getCountryCurrency(user.country);
  const currencyAll = getCountryCurrency(user.country);
  const currency = currencyAll[0];

  let x = getAuthCookie();
  const headers = {
    "Access-Control-Allow-Credentials": true,
    Authorization: x,
    "Content-type": "application/json; charset=UTF-8",
  };

  const fetchOrder = async () => {
    if (isWareHouse) {
      getWhOrderById(params.id)
        .then((data) => {
          setOrderById(data);
          setOrderByIdItems(data?.items);
        })
        .catch((err_data) => {
          console.log("no data", err_data);
        });
    } else {
      getOrderById(params.id)
        .then((data) => {
          setOrderById(data);
          setOrderByIdItems(data?.items);
        })
        .catch((err_data) => {
          console.log("no data", err_data);
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const deleteOrderById = (orderById) => {
    const data = JSON.stringify(orderById);
    myAxios
      .post("/order/cancel/" + `${params.id}` + "/", data, { headers: headers })
      .then((res) => {
        if (res) {
          setOrderByIdItems([]);
          setDeleteSuccess("Заказ удален !");
        } else {
          console.log("order error");
        }
      });
    setOrderByIdItems([]);
  };

  const handleStepperAction = (cb) => {
    cb().then(() => {
      fetchOrder();
    });
  };

  const status = `${orderById.status}`;

  return (
    <Page>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          {/*<Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>*/}
          {/*  New User*/}
          {/*</Button>*/}
        </Stack>
        <Typography variant="h4"> {t('ORDER_NR')} - {orderById._id}</Typography>
        <br/>
        {isWareHouse ?
            <>
              <div style={{textAlign: 'left'}}>{t('PRN')} :&nbsp;{orderById.user_id}</div>
              <div style={{textAlign: 'left'}}>
                {t('FULL_NAME')} : <strong>{orderById?.user_name?.split("_")[0]}&nbsp;{orderById?.user_name?.split("_")[1]}</strong>
              </div>
              <div style={{textAlign: 'left'}}>{t('EMAIL')} :&nbsp;{orderById.email}</div>
              <div style={{textAlign: 'left'}}>{t('PHONE')} :&nbsp;{orderById.phone}</div>
            </> : ''}

        <Card sx={{ mb: 6, mt: 4 }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t('NAME')}</TableCell>
                    <TableCell align="center">{t('VOLUME')}</TableCell>
                    <TableCell align="center">{t('POINTS')}</TableCell>
                    <TableCell align="center">{t('QUANTITY_HALF')}</TableCell>
                    <TableCell align="center">{t('PRICE')} ({currency})</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderByIdItems
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <TableRow
                        key={item.item_c_code}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          {item.name.split(",")[0]}
                        </TableCell>
                        <TableCell align="center">
                          {item.name.split(",")[1]}
                        </TableCell>
                        <TableCell align="center">{item.point}</TableCell>
                        <TableCell align="center">{item.count}</TableCell>
                        <TableCell align="center">{item.price}</TableCell>
                        {/*<TableCell align="right">{item.update_date.split('T')[0]}</TableCell>*/}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={orderByIdItems?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Scrollbar>
        </Card>
        {orderById && (
          <OrderStepper
            key={rowsPerPage}
            order={orderById}
            onAction={handleStepperAction}
          />
        )}
        <br />
        {orderByIdItems.length >= 1 && (
          <>
            <div>{t('TOTAL_POINTS')} : {orderById.orders_total_point} </div>
            <div style={{ fontWeight: "bolder" }}>
              {t('TOTAL_COST')} : {orderById.orders_total}&nbsp;{currency}{" "}
            </div>
            <br/>
            <div> <strong>{t('LINK_PAYMENT')}</strong> - <a href={orderById.payment_link} target="_blank" rel="noreferrer">{orderById.payment_link}</a></div>
            <br/>
            <div> {t('CREATED_DATE')} - {orderById.created_date.split("T")[0]}</div>
            <div>
              {" "}
              {t('UPDATE_DATE')} -{" "}
              {orderById.update_date !== null &&
                orderById.update_date.split("T")[0]}
            </div>

            <div>{t('ONLINE_PAY')} - {orderById.paid_online}</div>
            <div>{t('DISCOUNT')} - {orderById.discount}</div>
            <div>{t('WAREHOUSE_NR')} - {orderById.warehouse_id}</div>
            <div>{t('WAREHOUSE_TOTAL')} - {orderById.warehouse_total}</div>
            {isWareHouse && <div>{t('CUSTOMER')} - {orderById.user_name}</div>}
            <br />
            <div style={{ fontWeight: "bold" }}>
              {t('STATUS')} - <OrderStatus as="span" status={orderById.status} />
            </div>
            <br />

            {status === "new" && (
              <Button
                variant="contained"
                onClick={() => deleteOrderById(orderById)}
              >
                {t('DELETE_ORDER')}
              </Button>
            )}
          </>
        )}

        {/*{status === "cancel" && <div style={{color: "red"}}>Удален</div> }*/}
        <div>{deleteSuccess}</div>
        <br />
      </Container>
    </Page>
  );
};
export default MyOrdersItem;
