import { Typography } from "@mui/material";
import {useTranslation} from "../../providers";

export default function WhDecline({ order }) {
    const {t} = useTranslation();
  if (!order?.comments) {
    return null;
  }
  return (
    <div>
      <Typography>
        <b>{t('REASON')}</b>: {order.comments}
      </Typography>
    </div>
  );
}
