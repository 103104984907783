import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    Stack,
    IconButton,
    InputAdornment,
    Alert,
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
    CircularProgress,
    Box,
    Modal,
    DialogTitle,
    DialogContentText,
} from "@mui/material";
import Button from "@mui/material/Button";
import {LoadingButton} from "@mui/lab";
import Iconify from "../../../components/Iconify";
import {userLogin} from "../../../store/authSlice";
import {requestPassword} from "../../../utils/apiCalls";

export default function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {error, user, loginStatus} = useSelector((state) => state.auth);

    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required("Login is required"),
        password: Yup.string().required("Password is required"),
    });

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues: {
            email: "",
            password: "",
            remember: true,
        },
    });

    const onSubmit = (values) => {
        dispatch(userLogin(values));
        setValue("email", "");
        setValue("password", "");
    };

    useEffect(() => {
        if (user) {
            navigate("/dashboard", {replace: true});
        }
    }, [user, navigate]);

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [fullWidth] = useState(true);
    const [maxWidth] = useState("sm");
    const [showNoSuccessBanner, setShowNoSuccessBanner] = useState(false);
    const [showSuccessBanner, setShowSuccessBanner] = useState(false);
    const [resetEmail, setResetEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = () => {
        navigate("/");
        setOpen2(false);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{my: 2}}>
                {typeof error === "string" && <Alert severity="error">{error}</Alert>}
                <TextField
                    {...register("email")}
                    label="Email / ЛРН"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />
                <TextField
                    {...register("password")}
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    <Iconify
                                        icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loginStatus === "pending"}
            >
                Login
            </LoadingButton>
            <br/>
            <br/>
            <br/>
            <Button style={{border: "none"}} variant="outlined" onClick={handleClickOpen}>
                Forgot password
            </Button>
            <Dialog
                open={open}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        setLoading(true);
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const lnr = formJson.lnr;
                        console.log('lnr', lnr);
                        requestPassword(lnr)
                            .then((data) => {
                                setResetEmail(data?.email);
                                setShowSuccessBanner(true);
                                setOpen2(true);
                                console.log('resetEmail', data.email)
                            })
                            .catch((err_data) => {
                                setShowNoSuccessBanner(true);
                                setOpen2(true);
                                console.log("no data", err_data);
                            })
                            .finally(() => {
                                setLoading(false)
                            });
                    },
                }}
            >
                <DialogTitle>Восстановить Пароль</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите свой ЛНР. Мы вышлем ваш пароль на email
                        <br/>
                        <br/>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="lnr"
                        label="LNR"
                        type="input"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" onClick={handleClose}>Enter</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={open2}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    onClose={handleClose}
            >
                <DialogTitle>Восстановить Пароль</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {showNoSuccessBanner && (
                            <Alert sx={{mt: 3}} severity="error">
                                Ваш ЛНР не найден
                            </Alert>
                        )}
                        {showSuccessBanner && (
                            <Alert sx={{mt: 3}} severity="success">
                                Ваш пароль выслан на email: {resetEmail}
                            </Alert>)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2}>Ok</Button>
                </DialogActions>
            </Dialog>
            {loading ?
                <Box>
                    <Modal
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        open
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        sx={{
                            display: 'flex',
                            p: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >

                        <Box
                            sx={{
                                position: 'relative',
                                border: 'none',
                                p: 4,
                            }}
                        >
                            <CircularProgress/>
                        </Box>
                    </Modal>
                </Box>
                : ''}
        </form>
    );
}
