import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Grid,
} from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import NavConfig from "./NavConfig";
import Button from "@mui/material/Button";
import Iconify from "../../components/Iconify";
import {useTranslation} from "../../providers";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
    borderStyle: "solid",
  },
  backgroundColor: "white",
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({isOpenSidebar, onCloseSidebar}) {
    const {pathname} = useLocation();
    const {user} = useSelector((state) => state.auth);
    const {t} = useTranslation();

  const isDesktop = useResponsive("up", "lg");

  const photoURL = `/static/icons/crowns/${user.rank_id}.svg`;

  //copy to clipboard
  const [copySuccess, setCopySuccess] = useState(null);
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  let userId = `${user._id}`;

  const navConfig = NavConfig();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <Logo />
      </Box>
      <Box sx={{ mb: 2, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {user.first_name} {user.last_name}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {user.rank}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

            <Box sx={{px: 2.5, pb: 3, mt: 10}}>
                <a href={`/pdf/${t('QUALIFICATION_URL')}`}
                   target={'_blank'}
                   className={'button_report border_none f_w'}
                   style={{textDecoration: "none"}}
                >
                    <Iconify
                        icon="eva:download-fill"
                        width={20}
                        height={20}
                        color="#637381"
                    />
                    &nbsp;{t('QUALIFICATION')}
                </a>
                <br/>
                <a href={`/pdf/${t('MARKETING_PLAN_URL')}`}
                   target={'_blank'}
                   className={'button_report border_none f_w'}
                   style={{textDecoration: "none"}}
                >
                    <Iconify
                        icon="eva:download-fill"
                        width={20}
                        height={20}
                        color="#637381"
                    />
                    &nbsp;{t('MARKETING_PLAN')}
                </a>
                <br/>
                <a href={`/pdf/${t('ETHICS_URL')}`}
                   target={'_blank'}
                   className={'button_report border_none f_w'}
                   style={{textDecoration: "none"}}
                >
                    <Iconify
                        icon="eva:download-fill"
                        width={20}
                        height={20}
                        color="#637381"
                    />
                    &nbsp;{t('ETHICS')}
                </a>
                <br/>
                <Stack
                    alignItems="center"
                    spacing={3}
                    sx={{pt: 3, borderRadius: 2, position: "relative"}}
                >
                    {/*<Box sx={{ textAlign: 'center' }}>*/}
                    <Typography
                        variant="body2"
                        sx={{color: "text.secondary"}}
                        style={{textAlign: 'left'}}
                        onClick={(e) => copyToClipBoard("https://refferal_url")}
                    > {t('COPY_LINK')}&nbsp;:
                    </Typography>
                    <Grid container spacing={1} style={{border: "1px solid lightgray"}}>
                        <Grid item xs={5}>
                            <div
                                style={{
                                    textAlign: "left",
                                    color: "gray",
                                    fontWeight: "bolder",
                                }}
                            >
                                {user.last_name}&nbsp;{user.first_name}:&nbsp;
                                {user._id}
                            </div>
                        </Grid>
                        <Grid item xs={1} style={{marginLeft: "200px"}}>
                            <button
                                onClick={(e) => copyToClipBoard(`https://giter.world/pages/cooperation?id=${userId}#id`)}
                                style={{border: "none", color: "#2065d1"}}
                            >
                                <span className="material-icons blue">content_copy</span>
                            </button>
                        </Grid>
                    </Grid>
                    {copySuccess}
                    {/*</Box>*/}
                </Stack>
            </Box>
        </Scrollbar>
    );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          // sx={{ borderRightStyle: "solid" }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              borderRightStyle: "solid",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
