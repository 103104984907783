import {useState, useTransition} from "react";
import ActionsWrapper from "./ActionsWrapper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as Yup from "yup";
import { useFormik } from "formik";
import {postClientAction, postClientActionPickup} from "../../utils/ClientApi";
import {useTranslation} from "../../providers";

const validationSchema = Yup.object({
  address: Yup.string().required("Обязательное поле"),
});

export default function ClientDeliveryForm({ order, actionWrapper }) {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();

  const { submitForm, values, handleChange, resetForm, errors } = useFormik({
    initialValues: {
      address: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      actionWrapper(() =>
        postClientAction(order._id, "ready-delivery", values)
      );
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const allowPickUp = (values) => {
    console.log(values)
    actionWrapper(() =>
        postClientActionPickup(order._id, "pickup", values)
    );
  };

  const actions = [

    {
      label: `${t('PICKUP')}`,
      handler: allowPickUp,
      variant: "contained",
    },
    {
      label: `${t('ASK_FOR_DELIVERY')}`,
      handler: handleClickOpen,
      variant: "contained",
      inform: `${t('DELIVERY_PAYMENT_INFO')}`,
    },
  ];

  return (
    <div>
      <Dialog open={open} onClose={handleClickOpen}>
        <DialogTitle>{t('ASK_FOR_DELIVERY')}</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label={t('DELIVERY_ADDRESS')}
            name="address"
            type="text"
            fullWidth
            variant="standard"
            error={!!errors.address}
            onChange={handleChange}
          />
          <FormHelperText error>{errors.address}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('CANCEL')}</Button>
          <Button onClick={submitForm}>{t('REQUEST')}</Button>
        </DialogActions>
      </Dialog>
      <ActionsWrapper
        orderId={order._id}
        actionWrapper={actionWrapper}
        actions={actions}
      />
    </div>
  );
}
