import React, { useEffect, useState } from "react";

// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableSortLabel,
  CircularProgress, Tabs, Tab,
} from "@mui/material";

// components
import Page from "../components/Page";

import Scrollbar from "../components/Scrollbar";
import OrderStatus from "../components/OrderStatus";

import { getAllMyOrders, getAllWhOrders } from "../utils/ClientApi";
import { getTokenDetails } from "../utils/apiCalls";

import TableHead from "@mui/material/TableHead";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "../providers";

export default function User() {
  const [page, setPage] = useState(0);

  const [active, setActive] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [orders, setOrders] = useState();
  const [ordersCurrent, setOrdersCurrent] = useState();
  const [ordersCanceled, setOrdersCanceled] = useState();
  const [ordersDone, setOrdersDone] = useState();
  const { wh: isWareHouse } = getTokenDetails() ?? {};
  const {t} = useTranslation();

  const [orderDirection, setOrderDirection] = useState("asc");

  const navigate = useNavigate();

  // if (orders == "") (
  useEffect(() => {
    if (isWareHouse) {
      getAllWhOrders()
        .then((data) => {
          setOrders(data?.orders ?? []);
          setOrdersCurrent(data.orders);
          setOrdersCanceled(data.canceled);
          setOrdersDone(data.done);
        })
        .catch((err_data) => {
          console.log("no data", err_data);
        });
    } else {
      getAllMyOrders()
        .then((data) => {
          setOrders(data?.orders ?? []);
          setOrdersCurrent(data.orders);
          setOrdersCanceled(data.canceled);
          setOrdersDone(data.done);
        })
        .catch((err_data) => {
          console.log("no data", err_data);
        });
    }
  }, []);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const currentTab = () => {
    setOrders([...ordersCurrent])
  }
  const canceledTab = () => {
    setOrders([...ordersCanceled])
  }
  const closedTab = () => {
    setOrders([...ordersDone])
  }

  const handleSortRequestPriceTotal = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setOrders(sortArrayTotal(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayTotal = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...orders].sort((a, b) =>
        a.total < b.total ? 1 : b.total < a.total ? -1 : 0
      );
    } else {
      return [...orders].sort((a, b) =>
        a.total > b.total ? 1 : b.total > a.total ? -1 : 0
      );
    }
  };

  const handleSortRequestData = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setOrders(sortArrayDataUpdate(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayDataUpdate = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...orders].sort((a, b) =>
        a.created_date < b.created_date
          ? 1
          : b.created_date < a.created_date
          ? -1
          : 0
      );
    } else {
      return [...orders].sort((a, b) =>
        a.created_date > b.created_date
          ? 1
          : b.created_date > a.created_date
          ? -1
          : 0
      );
    }
  };

  const handleSortRequestStatus = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setOrders(sortArrayStatus(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayStatus = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...orders].sort((a, b) =>
        a.status < b.status ? 1 : b.status < a.status ? -1 : 0
      );
    } else {
      return [...orders].sort((a, b) =>
        a.status > b.status ? 1 : b.status > a.status ? -1 : 0
      );
    }
  };

  const handleSortRequestCount = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setOrders(sortArrayCount(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayCount = (orderDirection) => {
    if (orderDirection === "desc") {
      return [...orders].sort((a, b) =>
        a.item_count < b.item_count ? 1 : b.item_count < a.item_count ? -1 : 0
      );
    } else {
      return [...orders].sort((a, b) =>
        a.item_count > b.item_count ? 1 : b.item_count > a.item_count ? -1 : 0
      );
    }
  };

  const handleSortRequestUpdateDate = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setOrders(sortArrayUpdate(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayUpdate = (orderDirection) => {
    switch (orderDirection) {
      case "asc":
      default:
        return [...orders].sort((a, b) =>
          a.update_date > b.update_date
            ? 1
            : b.update_date > a.update_date
            ? -1
            : 0
        );
      case "desc":
        return [...orders].sort((a, b) =>
          a.update_date < b.update_date
            ? 1
            : b.update_date < a.update_date
            ? -1
            : 0
        );
    }
  };

  const handleSortRequestName = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setOrders(sortArrayName(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayName = (orderDirection) => {
    switch (orderDirection) {
      case "asc":
      default:
        return [...orders].sort((a, b) =>
            a.name > b.name
                ? 1
                : b.name > a.name
                ? -1
                : 0
        );
      case "desc":
        return [...orders].sort((a, b) =>
            a.name < b.name
                ? 1
                : b.name < a.name
                ? -1
                : 0
        );
    }
  };

  const handleSortRequestPhone = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setOrders(sortArrayPhone(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayPhone = (orderDirection) => {
    switch (orderDirection) {
      case "asc":
      default:
        return [...orders].sort((a, b) =>
            a.phone > b.phone
                ? 1
                : b.phone > a.phone
                ? -1
                : 0
        );
      case "desc":
        return [...orders].sort((a, b) =>
            a.phone < b.phone
                ? 1
                : b.phone < a.phone
                ? -1
                : 0
        );
    }
  };

  const handleSortRequestEmail = (event) => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    setOrders(sortArrayEmail(orderDirection));
    setActive(event.target.id);
  };

  const sortArrayEmail = (orderDirection) => {
    switch (orderDirection) {
      case "asc":
      default:
        return [...orders].sort((a, b) =>
            a.email > b.email
                ? 1
                : b.email > a.email
                ? -1
                : 0
        );
      case "desc":
        return [...orders].sort((a, b) =>
            a.email < b.email
                ? 1
                : b.email < a.email
                ? -1
                : 0
        );
    }
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page title="Мои заказы">
      {!orders ? (
        <CircularProgress />
      ) : (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            className={'goods_no_mob'}
          >
            <Typography variant="h4" gutterBottom>
              {t('MY_ORDERS')}
            </Typography>
            <Tabs value={currentTabIndex} onChange={handleTabChange}>
              <Tab label={t('CURRENT')} onClick={currentTab}/>
              <Tab label={t('CANCELED')} onClick={canceledTab}/>
              <Tab label={t('CLOSED')} onClick={closedTab}/>
            </Tabs>
          </Stack>
          <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
              className={'goods_no_desktop'}
          >
            <Typography variant="h4" gutterBottom>
              {t('MY_ORDERS')}
            </Typography>
          </Stack>
          <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
              className={'goods_no_desktop'}>
            <Tabs value={currentTabIndex} onChange={handleTabChange}>
              <Tab label={t('CURRENT')} onClick={currentTab}/>
              <Tab label={t('CANCELED')} onClick={canceledTab}/>
              <Tab label={t('CLOSED')} onClick={closedTab}/>
            </Tabs>
          </Stack>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 1000 }}>
                <Table aria-label="simple table" className={"my_orders_table"}>
                  <TableHead>
                    <TableRow>
                      {(isWareHouse) ?<>
                         <TableCell
                          key={1}
                          id={"1"}
                          onClick={handleSortRequestName}
                          align="center"
                          cursor="pointer"
                      >
                        <TableSortLabel
                            key={1}
                            id={"1"}
                            active={active === "1"}
                            direction={orderDirection}
                        >
                          {t('NAME')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                          key={2}
                          id={"2"}
                          onClick={handleSortRequestPhone}
                          align="center"
                          cursor="pointer"
                      >
                        <TableSortLabel
                            key={2}
                            id={"2"}
                            active={active === "2"}
                            direction={orderDirection}
                        >
                          {t('PHONE')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                          key={3}
                          id={"3"}
                          onClick={handleSortRequestEmail}
                          align="center"
                          cursor="pointer"
                      >
                        <TableSortLabel
                            key={3}
                            id={"3"}
                            active={active === "3"}
                            direction={orderDirection}
                        >
                          {t('EMAIL')}
                        </TableSortLabel>
                      </TableCell>
                      </>: ''
                      }
                      <TableCell
                        key={4}
                        id={"4"}
                        onClick={handleSortRequestData}
                        align="center"
                        cursor="pointer"
                      >
                        <TableSortLabel
                          key={4}
                          id={"4"}
                          active={active === "4"}
                          direction={orderDirection}
                        >
                          {t('CREATED_DATE')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        key={5}
                        id={"5"}
                        onClick={handleSortRequestCount}
                        align="center"
                      >
                        <TableSortLabel
                          key={5}
                          id={"5"}
                          active={active === "5"}
                          direction={orderDirection}
                        >
                          {t('QUANTITY_ITEMS')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        key={6}
                        id={"6"}
                        onClick={handleSortRequestPriceTotal}
                        align="center"
                      >
                        <TableSortLabel
                          key={6}
                          id={"6"}
                          active={active === "6"}
                          direction={orderDirection}
                        >
                          {t('AMOUNT')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        key={7}
                        id={"7"}
                        onClick={handleSortRequestStatus}
                        align="center"
                      >
                        <TableSortLabel
                          key={7}
                          id={"7"}
                          active={active === "7"}
                          direction={orderDirection}
                        >
                          {t('STATUS')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        key={8}
                        id={"8"}
                        onClick={handleSortRequestUpdateDate}
                        align="center"
                      >
                        <TableSortLabel
                          key={8}
                          id={"8"}
                          active={active === "8" ? true : false}
                          direction={orderDirection}
                        >
                          {t('UPDATE_DATE')}
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => (
                        <TableRow
                          hover
                          key={item.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                          onClick={() => navigate(`${item.id}`)}
                        >
                          {(isWareHouse) ?<>
                          <TableCell align="center">{item.user_name.split("_")[0]}&nbsp;{item.user_name.split("_")[1]}</TableCell>
                          <TableCell align="center">{item.phone}</TableCell>
                          <TableCell align="center">{item.email}</TableCell>
                          </>: ''}
                          <TableCell align="center">
                            {item.created_date.split("T")[0]}
                          </TableCell>
                          <TableCell align="center">{item.item_count}</TableCell>
                          <TableCell align="center">{item.total}</TableCell>
                          <TableCell align="center">
                            <OrderStatus status={item.status} />
                          </TableCell>
                          <TableCell align="center">
                            {item.update_date !== null &&
                              item.update_date.split("T")[0]}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={orders.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      )}
    </Page>
  );
}
