import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material";
import Iconify from "../Iconify";
import Counter from "../Counter";
import {useTranslation} from "../../providers";

const RootStyle = styled("div")(({ theme }) => ({
  height: 24,
  zIndex: 999,
  cursor: "pointer",
  alignItems: "center",
  transition: theme.transitions.create("opacity"),
  color: theme.palette.primary.main,
}));

export default function OrderTable({
  order,
  partialOrder,
  onChange,
  editable,
}) {
  const handleRemoveItem = (item) => {
    // onChange(order.items.filter((i) =>  i.item_c_code !== item.item_c_code));
    onChange(partialOrder.items.filter((i) =>  i.item_c_code !== item.item_c_code));
  };
  const handleQuantityChange = (item, quantity) => {
    onChange(
      partialOrder.items.map((i) =>
        i.item_c_code === item.item_c_code
          ? { ...i, count: Number(quantity) }
          : i
      )
    );
  };
  const {t} = useTranslation();

  return (
    <TableContainer sx={{ maxWidth: 800 }} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('NAME')}</TableCell>
            <TableCell>{t('QUANTITY_HALF')}</TableCell>
            {editable && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {partialOrder.items.map((item) => (
            <TableRow
              key={item.item_c_code}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.name}
              </TableCell>
              <TableCell align="right">
                {editable ? (
                  <Counter
                    value={item.count ?? 1}
                    onChange={(value) => handleQuantityChange(item, value)}
                  />
                ) : (
                  item.count
                )}
              </TableCell>
              {editable && (
                <TableCell align="right">
                  <IconButton
                    onClick={() => handleRemoveItem(item)}
                    sx={{ width: 40, height: 40 }}
                  >
                    <RootStyle>
                      <Iconify
                        icon="mdi:trash"
                        sx={{
                          width: "24px",
                          height: "24px",
                        }}
                        with="24px"
                        height="24px"
                      />
                    </RootStyle>
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
