import React, { useState } from "react";
import { useSelector } from "react-redux";

// @mui
import {
  Container,
  Typography,
  Box,
  alpha,
  CircularProgress,
   Modal
} from "@mui/material";
// components
import Page from "../components/Page";
// sections

import {getAuthCookie, getTarget, myAxios} from "../utils/apiCalls";
import { getCountryCurrency } from "../utils/getCountryCurrency";
import palette from "../theme/palette";
import Iconify from "../components/Iconify";
import Button from "@mui/material/Button";
import {useTranslation} from "../providers";

// ----------------------------------------------------------------------

export default function DashboardApp(...props) {
  const [target, setTarget] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();


  let currentDate = new Date().toJSON().slice(0, 10);

  function getReport() {
    setLoading(true);
    myAxios
        .get(`/cabinet/my-network-file/`, {
          headers: {
            "Access-Control-Allow-Credentials": true,
            Authorization: getAuthCookie(),
          },
          responseType: 'blob',
          timeout: 120000,
        })
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${currentDate}` + '-my-network-' + `${user._id}` + '.xlsx');
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((err_data) => {
          console.log("no data", err_data);
        })
        .finally(() => {setLoading(false) });

  }

  // TODO: get trarget from my-target endpoint

  // grads level image
  const grads = [
    {
      id: 1,
      grad: "<div class=item style=height:20px;margin-top:250px;></div>",
      gradClass:
        "<div class=item_white style=height:20px;margin-top:250px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:20px;margin-top:250px;>" +
        "<div class='icon_crowns'>" +
        "<img src='./static/icons/crowns/1.svg'/></div></div>",
    },
    {
      id: 2,
      grad: "<div class=item style=height:40px;margin-top:230px;></div>",
      gradClass:
        "<div class=item_white style=height:40px;margin-top:230px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:40px;margin-top:230px;><div class='icon_crowns'><img src='./static/icons/crowns/2.svg'/></div></div>",
    },
    {
      id: 3,
      grad: "<div class=item style=height:60px;margin-top:210px;></div>",
      gradClass:
        "<div class=item_white style=height:60px;margin-top:210px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:60px;margin-top:210px;><div class='icon_crowns'><img src='./static/icons/crowns/3.svg'/></div></div>",
    },
    {
      id: 4,
      grad: "<div class=item style=height:80px;margin-top:190px;></div>",
      gradClass:
        "<div class=item_white style=height:80px;margin-top:190px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:80px;margin-top:190px;><div class='icon_crowns'><img src='./static/icons/crowns/4.svg'/></div></div>",
    },
    {
      id: 5,
      grad: "<div class=item style=height:100px;margin-top:170px;></div>",
      gradClass:
        "<div class=item_white style=height:100px;margin-top:170px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:100px;margin-top:170px;><div class='icon_crowns'><img src='./static/icons/crowns/5.svg'/></div></div>",
    },
    {
      id: 6,
      grad: "<div class=item style=height:120px;margin-top:150px;></div>",
      gradClass:
        "<div class=item_white style=height:120px;margin-top:150px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:120px;margin-top:150px;><div class='icon_crowns'><img src='./static/icons/crowns/6.svg'/></div></div>",
    },
    {
      id: 7,
      grad: "<div class=item style=height:140px;margin-top:130px;></div>",
      gradClass:
        "<div class=item_white style=height:140px;margin-top:130px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:140px;margin-top:130px;><div class='icon_crowns'><img src='./static/icons/crowns/7.svg'/></div></div>",
    },
    {
      id: 8,
      grad: "<div class=item style=height:160px;margin-top:110px;></div>",
      gradClass:
        "<div class=item_white style=height:160px;margin-top:110px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:160px;margin-top:110px;><div class='icon_crowns'><img src='./static/icons/crowns/8.svg'/></div></div>",
    },
    {
      id: 9,
      grad: "<div class=item style=height:180px;margin-top:90px;></div>",
      gradClass:
        "<div class=item_white style=height:180px;margin-top:90px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:180px;margin-top:90px;><div class='icon_crowns'><img src='./static/icons/crowns/9.svg'/></div></div>",
    },
    {
      id: 10,
      grad: "<div class=item style=height:200px;margin-top:70px;></div>",
      gradClass:
        "<div class=item_white style=height:200px;margin-top:70px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:200px;margin-top:70px;><div class='icon_crowns'><img src='./static/icons/crowns/10.svg'/></div></div>",
    },
    {
      id: 11,
      grad: "<div class=item style=height:220px;margin-top:50px;></div>",
      gradClass:
        "<div class=item_white style=height:220px;margin-top:50px;></div>",
      gradClassCircle:
        "<div class=item_white style=height:220px;margin-top:50px;><div class='icon_crowns'><img src='./static/icons/crowns/11.svg'/></div></div>",
    },
  ];

  let targetId = `${target._id}`;

  const [gradBar, setGradBar] = useState(grads);

  if (target === "")
    getTarget()
      .then((data) => {
        console.log("target data", data);
        setTarget(data);
      })
      .catch((err_data) => {
        console.log("no data", err_data);
        // TODO: Make allert!
      });

  console.log("props in DashboardApp", user);

  if (!user && !target) {
    return <></>;
  } else {
    let lo_circle = (`${user.current_data.current_lo}` * 100) / `${target.lo}`;
    let go_circle = (`${user.current_data.current_go}` * 100) / `${target.go}`;
    let ngo_circle = (`${user.current_data.current_ngo}` * 100) / `${target.ngo}`;
    let olg_circle = (`${user.current_data.current_olg}` * 100) / `${target.olg}`;
    const currencyAll = getCountryCurrency(user.country);
    const currency = currencyAll[1];
    // const currency = getCountryCurrency(user.country);

    // let w =moment(this.state.dateselected, "MM-YYYY")
    const [month, year] = `${
      user?.closed_data[user?.closed_data?.length - 1]?.closed_month
    }`.split("-");

    const date = new Date(parseInt(year), parseInt(month) - 1);

    return (
      <div>
        <Page title="Dashboard">
          <Container maxWidth="xl">
            <Box
              className={"graph_vertical"}
              sx={{
                backgroundColor: alpha(palette.primary.lighter, 0.45),
                ".icon_crowns": {
                  // border: `2px solid ${palette.primary.dark}`,
                  boxShadow: `0px 0px 7px 2px ${palette.primary.dark}`,
                },
                ".item": {
                  backgroundColor: palette.primary.dark,
                },
              }}
            >
              <Box className={"graphW"}>
                <div className={"row"}>
                  <div className={"col-1"}>&nbsp;</div>
                  {gradBar.map(
                    (item) =>
                      (item.id < targetId && (
                        <div
                          key={item.id}
                          className={"col-1"}
                          dangerouslySetInnerHTML={{ __html: item.gradClass }}
                        />
                      )) ||
                      (item.id == targetId && (
                        <div
                          key={item.id}
                          className={"col-1"}
                          dangerouslySetInnerHTML={{
                            __html: item.gradClassCircle,
                          }}
                        />
                      )) ||
                      (item.id > targetId && (
                        <div
                          key={item.id}
                          className={"col-1"}
                          dangerouslySetInnerHTML={{ __html: item.grad }}
                        />
                      ))
                  )}
                </div>
              </Box>
            </Box>
            {/*row: icon, name,  diagrams*/}
            <div className={"graphW2"}>
              <div className={"row mobile_circle"}>
                <div className={"col-1"}>
                  <Box className={"big_icon_pos"}>
                    <Box
                      className={"big_icon"}
                      sx={{
                        boxShadow: "0px 0px 15px 4px rgba(158,52,64,1)",
                      }}
                    >
                      <img
                        src={`./static/icons/crowns/${user.rank_id}.svg`}
                        alt={"rank_icon"}
                      />
                    </Box>
                  </Box>
                  <Typography variant="h5" textAlign="center" color="primary">
                    {user.rank}
                  </Typography>
                </div>
                <Box className={"col-3"} textAlign="center" mt={3}>
                  <Typography variant="h5" textAlign="center">
                    {user.last_name}&nbsp;{user.first_name}
                  </Typography>
                  <Typography textAlign="center">
                    ЛРН:&nbsp;{user._id}
                  </Typography>
                  <br />
                </Box>
                {/*circle diagrams*/}
                <div className={"col-2"}>
                  <Box
                    className={"single-chart"}
                    sx={{
                      ".chart, .chart_total": {
                        stroke: palette.primary.dark,
                      },
                    }}
                  >
                    <svg viewBox="0 0 35 35" className="pie">
                      <circle
                        className={"chart_total"}
                        r="45%"
                        cx="50%"
                        cy="50%"
                        strokeDasharray={`100 100`}
                      />
                      <circle
                        className={"chart"}
                        r="45%"
                        cx="50%"
                        cy="50%"
                        strokeDasharray={`${lo_circle} 100`}
                      />
                    </svg>
                    {`${lo_circle}` > 100 && (
                      <span className="material-symbols-outlined star_lo">
                        stars
                      </span>
                    )}
                    <div className={"circle_line_absolute"}>
                      {user.current_data.current_lo}
                      <div className={"circle_underline"}>{target.lo}</div>
                    </div>
                  </Box>
                  <Typography fontWeight={600}>{t('PV')}</Typography>
                </div>
                <div className={"col-2 media_circle"}>
                  <Box
                    className={"single-chart"}
                    sx={{
                      ".chart, .chart_total": {
                        stroke: palette.primary.dark,
                      },
                    }}
                  >
                    <svg viewBox="0 0 35 35" className="pie">
                      <circle
                        className={"chart_total"}
                        r="45%"
                        cx="50%"
                        cy="50%"
                        strokeDasharray={`100 100`}
                      />
                      <circle
                        className={"chart"}
                        r="45%"
                        cx="50%"
                        cy="50%"
                        strokeDasharray={`${go_circle} 100`}
                      />
                    </svg>
                    {`${go_circle}` > 100 && (
                      <span className="material-symbols-outlined star_go">
                        stars
                      </span>
                    )}
                    <div className={"circle_line_absolute2"}>
                      {user.current_data.current_go}
                      <div className={"circle_underline"}>{target.go}</div>
                    </div>
                  </Box>
                  <Typography fontWeight={600}>{t('GV')}</Typography>
                </div>

                <div className={"col-2 media_circle"}>
                  <Box
                    className={"single-chart"}
                    sx={{
                      ".chart, .chart_total": {
                        stroke: palette.primary.dark,
                      },
                    }}
                  >
                    <svg viewBox="0 0 35 35" className="pie">
                      <circle
                        className={"chart_total"}
                        r="45%"
                        cx="50%"
                        cy="50%"
                        strokeDasharray={`100 100`}
                      />
                      <circle
                        className={"chart"}
                        r="45%"
                        cx="50%"
                        cy="50%"
                        strokeDasharray={`${ngo_circle} 100`}
                      />
                    </svg>
                    {`${ngo_circle}` > 100 && (
                      <span className="material-symbols-outlined star_ngo">
                        stars
                      </span>
                    )}
                    <div className={"circle_line_absolute3"}>
                      {user.current_data.current_ngo}
                      <div className={"circle_underline"}>{target.ngo}</div>
                    </div>
                  </Box>
                  <Typography fontWeight={600}>{t('CGV')}</Typography>
                </div>

                <div className={"col-2 media_circle"}>
                  <Box
                    className={"single-chart"}
                    sx={{
                      ".chart, .chart_total": {
                        stroke: palette.primary.dark,
                      },
                    }}
                  >
                    <svg className="pie" viewBox="0 0 35 35">
                      <circle
                        className={"chart_total"}
                        r="45%"
                        cx="50%"
                        cy="50%"
                        strokeDasharray={`100 100`}
                      />
                      <circle
                        className={"chart"}
                        r="45%"
                        cx="50%"
                        cy="50%"
                        strokeDasharray={`${olg_circle} 100`}
                      />
                    </svg>
                    {`${olg_circle}` > 100 && (
                      <span className="material-symbols-outlined star_olg">
                        stars
                      </span>
                    )}
                    <div className={"circle_line_absolute4"}>
                      {user.current_data.current_olg}
                      <div className={"circle_underline"}>{target.olg}</div>
                    </div>
                  </Box>
                  <Typography fontWeight={600}>{t('PGV')}</Typography>
                </div>
                <div className={"col-2 media_circle"}>
                  <Box
                      className={"single-chart"}
                      sx={{
                        ".chart, .chart_total": {
                          stroke: palette.primary.dark,
                        },
                      }}
                  >
                    <svg className="pie" viewBox="0 0 35 35">
                      <circle
                          className={"chart_total"}
                          r="45%"
                          cx="50%"
                          cy="50%"
                          strokeDasharray={`100 100`}
                      />
                      <circle
                          className={"chart"}
                          r="45%"
                          cx="50%"
                          cy="50%"
                          strokeDasharray={`${olg_circle} 100`}
                      />
                    </svg>
                    {`${olg_circle}` > 100 && (
                        <span className="material-symbols-outlined star_olg">
                        stars
                      </span>
                    )}
                    <div className={"circle_line_absolute5"}>
                      {user.current_data.active_lo}
                    </div>
                  </Box>
                  <Typography fontWeight={600}>{t('PV')}<br/>{t('FOR_11')}</Typography>
                </div>
              </div>
            </div>
            {/*circle diagrams end*/}
            <Box pt={4}>
              <Typography variant="h4">{t('CLOSED_MONTH')}</Typography>
              {/*<div className={'title_small'}> {months[a]}&nbsp;{b}</div>*/}
              <div className={"month_align"}>
                {user?.closed_data.length === 0 ? '' : date?.toLocaleString(`${t('LANG')}`, { month: "long", year: "numeric" }) }
              </div>
              <br />
              <div className={"row"}>
                <div
                  className={"col-1 mt_10 mb_10"}
                  style={{ textAlign: "left", paddingTop: "10px" }}
                >
                  <div className={"material-symbols-outlined bonus_icon"}>
                    monetization_on
                  </div>
                  <Typography mt={1} ml={7} variant="h6">
                    {t('BONUSES')}
                  </Typography>
                </div>
              </div>
              <Box className={"line_container"}>
                <div className={"row row_bg container"}>
                  <div className={"col-12 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                        {user?.closed_data.length === 0 ? 0 : user?.closed_data[user.closed_data.length - 1].added_bonus }
                    </div>
                    <div className={"title_small"}>{t('ACCURED_BONUSES')}</div>
                  </div>
                </div>
                {/*<div className={"row row_bg container"}>*/}
                {/*    <div className={"col-6 col_bd mt_10 mb_10"}>*/}
                {/*        <div className={'title'}>{user.closed_data[0].auto_bonus}No data</div>*/}
                {/*        <div className={'title_small'}>Авто бонус</div>*/}
                {/*    </div>*/}
                {/*    <div className={"col-6 col_bd mt_10 mb_10"}>*/}
                {/*        <div className={'title'}>{user.closed_data[0].added_bonus}</div>*/}
                {/*        <div className={'title_small'}>Начисленный бонус</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
              </Box>
              <div className={"row"}>
                <div
                  className={"col-1 mt_10 mb_10"}
                  style={{ textAlign: "left", paddingTop: "10px" }}
                >
                  <div className={"material-symbols-outlined bonus_icon"}>
                    leaderboard
                  </div>
                  <Typography mt={1} ml={7} variant="h6">
                    {t('VOLUMES')}
                  </Typography>
                </div>
              </div>
              <div className={"line_container"}>
                <div className={"row row_bg container"}>
                  <div className={"col-3 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 : user.closed_data[user.closed_data.length - 1].closed_lo}
                    </div>
                    <div className={"title_small"}>{t('PV')}</div>
                  </div>
                  <div className={"col-3 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 : user.closed_data[user.closed_data.length - 1].closed_go}
                    </div>
                    <div className={"title_small"}>{t('GV')}</div>
                  </div>
                  <div className={"col-3 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 : user.closed_data[user.closed_data.length - 1].closed_ngo}
                    </div>
                    <div className={"title_small"}>{t('CGV')}</div>
                  </div>
                  <div className={"col-3 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 : user.closed_data[user.closed_data.length - 1].closed_olg}
                    </div>
                    <div className={"title_small"}>{t('PGV')}</div>
                  </div>
                </div>
              </div>
              <div className={"row"}>
                <div
                  className={"col-2 mt_10 mb_10"}
                  style={{ textAlign: "left", paddingTop: "10px" }}
                >
                  <div className={"material-symbols-outlined bonus_icon"}>
                    monetization_on
                  </div>
                  <Typography mt={1} ml={7} variant="h6">
                    {t('QUALIFICATIONS')}
                  </Typography>
                </div>
                {/*<div className={'col-1 mt_10 mb_10'}>*/}
                {/*    <div className={'row'}>*/}
                {/*        <div className={'col-1'}>*/}
                {/*        <div className={"material-symbols-outlined bonus_icon"}>radio_button_checked</div>*/}
                {/*        </div>*/}
                {/*        <div className={'col-10'} >*/}
                {/*            Квалификация*/}
                {/*        </div>*/}
                {/*    </div>*/}
              </div>
              <div className={"line_container"}>
                <div className={"row row_bg container"}>
                  <div className={"col-2 col_bd mt_10 mb_10"}>
                    <div className={"title"}>{currency}</div>
                    <div className={"title_small"}>{t('COUNTRY')}</div>
                  </div>
                  <div className={"col-2 col_bd mt_10 mb_10"}>
                    <div className={"title"}>{user?.closed_data.length === 0 ? 0 : user.closed_data[user.closed_data.length - 1].pr2}</div>
                    <div className={"title_small"}>{t('TOP_SELLER')}</div>
                  </div>
                  <div className={"col-2 col_bd mt_10 mb_10"}>
                    <div className={"title"}>{user?.closed_data.length === 0 ? 0 : user.closed_data[user.closed_data.length - 1].pr3}</div>
                    <div className={"title_small"}>{t('RECRUITMENT_LEADER')}</div>
                  </div>
                  <div className={"col-2 col_bd mt_10 mb_10"}>
                    <div className={"title"}>{user?.closed_data.length === 0 ? 0 : user.closed_data[user.closed_data.length - 1].pr4}</div>
                    <div className={"title_small"}>Giter Holiday</div>
                  </div>
                  <div className={"col-2 col_bd mt_10 mb_10"}>
                    <div className={"title"}>{user?.closed_data.length === 0 ? 0 : user.closed_data[user.closed_data.length - 1].pr5}</div>
                    <div className={"title_small"}>Giter best</div>
                  </div>
                  <div className={"col-2 col_bd mt_10 mb_10"}>
                    <div className={"title"}>{user?.closed_data.length === 0 ? 0 : user.closed_data[user.closed_data.length - 1].pr6}</div>
                    <div className={"title_small"}>{t('STABILITY')}</div>
                  </div>
                </div>
              </div>
              <div className={"row"}>
                <div
                  className={"col-1 mt_10 mb_10"}
                  style={{ textAlign: "left" }}
                >
                  <div className={"material-symbols-outlined bonus_icon"}>
                    lan
                  </div>
                  <Typography mt={1} ml={7} variant="h6">
                    {t('NETWORK')}
                  </Typography>
                </div>
              </div>
              <div className={"line_container"}>
                <div className={"row row_bg container"}>
                  <div className={"col-4 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 :
                          user.closed_data[user.closed_data.length - 1]
                              .total_distributors
                      }
                    </div>
                    <div className={"title_small"}>{t('DISTRIBUTORS')}</div>
                  </div>
                  <div className={"col-4 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 :
                          user.closed_data[user.closed_data.length - 1]
                              .active_users_network
                      }
                    </div>
                    <div className={"title_small"}>
                      {t('GV')}/{t('PGV')}
                      <br />
                      {t('ACTIVE_DISTRIBUTORS')}
                      <br />
                      {t('LO_MORE_0')}
                    </div>
                  </div>
                  <div className={"col-4 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 :
                          user.closed_data[user.closed_data.length - 1]
                              .qualified_distributors
                      }
                    </div>
                    <div className={"title_small"}>
                      {t('GV')}/{t('PGV')}
                      <br />
                      {t('QUALIFIED_DISTRIBUTORS')}
                      <br />
                      {t('PV_GREATER_25P')}
                    </div>
                  </div>
                  <hr className={"hr_dashboard"} />
                </div>
              </div>
              <div className={"line_container"}>
                <div className={"row row_bg container"}>
                  <div className={"col-6 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 :
                          user.closed_data[user.closed_data.length - 1]
                              .q_directors_l
                      }
                    </div>
                    <div className={"title_small"}>
                      {t('QUALIFIED_DIRECTORS_1')}
                    </div>
                  </div>
                  <div className={"col-6 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 :
                          user.closed_data[user.closed_data.length - 1]
                              .q_directors_d
                      }
                    </div>
                    <div className={"title_small"}>
                      {t('DEPTH_LEVEL')}
                    </div>
                  </div>
                  <hr className={"hr_dashboard"} />
                </div>
              </div>
              <div className={"line_container"}>
                <div className={"row row_bg container"}>
                  <div className={"col-6 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 :
                          user.closed_data[user.closed_data.length - 1]
                              .first_line_beginners
                      }
                    </div>
                    <div className={"title_small"}>
                      {t('NEW_RECRUITS')}
                    </div>
                  </div>
                  <div className={"col-6 col_bd mt_10 mb_10"}>
                    <div className={"title"}>
                      {user?.closed_data.length === 0 ? 0 :
                          user.closed_data[user.closed_data.length - 1]
                              .total_beginners
                      }
                    </div>
                    <div className={"title_small"}>{t('TOTAL_RECRUITS')}</div>
                  </div>
                  <hr className={"hr_dashboard"} />
                </div>
              </div>
            </Box>
            <br/>
            <br/>
            {user?.closed_data.length > 0 ?
            <Button
                className={'button_report'}
                onClick={getReport}
                style={{textTransform:'uppercase'}}
            ><Iconify
                icon="eva:download-fill"
                width={24}
                height={24}
                color="#637381"
            />
              &nbsp;{t('DOWNLOAD_BONUS')}</Button>
                :''}
            <br/>
            {loading ?
                <Box>
                  <Modal
                      disablePortal
                      disableEnforceFocus
                      disableAutoFocus
                      open
                      aria-labelledby="server-modal-title"
                      aria-describedby="server-modal-description"
                      sx={{
                        display: 'flex',
                        p: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                  >

                    <Box
                        sx={{
                          position: 'relative',
                          border: 'none',
                          p: 4,
                        }}
                    >
                      <CircularProgress />
                    </Box>
                  </Modal>
                </Box>
                : '' }
          </Container>
        </Page>
      </div>
    );
  }
}
