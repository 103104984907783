export const getCountryCurrency = (country) => {

  let currency = "MDL";
  let currency_Country = "MDL";

  if (country === "001") {
    currency = "MDL";
    currency_Country = "MDL";
  }
  if (country === "002") {
    currency = "EUR";
    currency_Country = "UAH";
  }
  if (country === "003") {
    currency = "RUB";
    currency_Country = "RUB";
  }
  if (country === "004") {
    currency = "BYN";
    currency_Country = "BYN";
  }
  if (country === "005") {
    currency = "KZT";
    currency_Country = "KZT";
  }
  if (country === "006") {
    currency = "UZS";
    currency_Country = "UZS";
  }
  if (country === "007") {
    currency = "TJS";
    currency_Country = "TJS";
  }
  if (country === "008") {
    currency = "RON";
    currency_Country = "RON";
  }
  if (country === "009") {
    currency = "EUR";
    currency_Country = "EUR";
  }
  if (country === "010") {
    currency = "EUR";
    currency_Country = "EUR";
  }
  if (country === "011") {
    currency = "SIT";
    currency_Country = "SIT";
  }
  if (country === "012") {
    currency = "TRY";
    currency_Country = "TRY";
  }
  if (country === "013") {
    currency = "AUD";
    currency_Country = "AUD";
  }
  if (country === "014") {
    currency = "EUR";
    currency_Country = "EUR";
  }
  if (country === "015") {
    currency = "GBP";
    currency_Country = "GBP";
  }
  if (country === "016") {
    currency = "KGS";
    currency_Country = "KGS";
  }
  if (country === "017") {
    currency = "GEL";
    currency_Country = "GEL";
  }
  if (country === "018") {
    currency = "NIS";
    currency_Country = "NIS";
  }
  if (country === "019") {
    currency = "EUR";
    currency_Country = "EUR";
  }
  if (country === "020") {
    currency = "EUR";
    currency_Country = "EUR";
  }
  if (country === "021") {
    currency = "EUR";
    currency_Country = "EUR";
  }
  if (country === "022") {
    currency = "GBP";
    currency_Country = "GBP";
  }
  if (country === "023") {
    currency = "EUR";
    currency_Country = "EUR";
  }
  if (country === "024") {
    currency = "EUR";
    currency_Country = "EUR";
  }
  if (country === "025") {
    currency = "CZK";
    currency_Country = "CZK";
  }
  if (country === "026") {
    currency = "EUR";
    currency_Country = "EUR";
  }
  if (country === "027") {
    currency = "PLZ";
    currency_Country = "PLZ";
  }
  if (country === "028") {
    currency = "CHF";
    currency_Country = "CHF";
  }

  return [currency, currency_Country];
};
