import palette from "../theme/palette";
import { STATUS } from "./constants";
import {useTranslation} from "../providers";

export function haveDuplicates(arr1, arr2) {
  // Combine both arrays into a single array
  const combinedArray = [...arr1, ...arr2];

  // Create a Set to store unique elements
  const uniqueElements = new Set();

  for (const item of combinedArray) {
    if (uniqueElements.has(item)) {
      // Found a duplicate, return true
      return true;
    }
    // Add the element to the Set
    uniqueElements.add(item);
  }

  // No duplicates found, return false
  return false;
}
// export const STATUS = {
//   NEW: "new",
//   USER_CANCEL: "user_cancel",
//   WAREHOUSE_PENDING: "warehouse_pending",
//   WAREHOUSE_DECLINED: "warehouse_declined",
//   WAREHOUSE_CHECKED_ACCEPTED: "warehouse_checked_accepted",
//   WAREHOUSE_CHECKED_DECLINED: "warehouse_checked_declined",
//   WAREHOUSE_CHECKED_CHANGE: "warehouse_checked_change",
//   USER_ACCEPTED: "user_accepted",
//   USER_DECLINED: "user_declined",
//   READY: "ready",
//   READY_FOR_DELIVERY: "ready_for_delivery",
//   DELIVERY_IN_PROGRESS: "delivery_in_progress",
//   DONE: "done",
// };



export function MyComponent() {
  const {t} = useTranslation();

  const ORDER_STATUSES = {
    [STATUS.NEW]: {
      label: `${t('NEW')}`,
      color: palette.grey[600],
    },
    [STATUS.USER_CANCEL]: {
      label: `${t('CANCELED_BY_DISTRIBUTOR')}`,
      color: palette.warning.dark,
    },
    [STATUS.WAREHOUSE_PENDING]: {
      label: `${t('IS_WAITING_BY_WAREHOUSE')}`,
      color: palette.warning.dark,
    },
    [STATUS.WAREHOUSE_DECLINED]: {
      label: `${t('CANCELED_BY_WAREHOUSE')}`,
      color: palette.error.dark,
    },
    [STATUS.WAREHOUSE_CHECKED_ACCEPTED]: {
      label: `${t('ACCEPTED_WAREHOUSE')}`,
      color: palette.warning.dark,
    },
    [STATUS.WAREHOUSE_CHECKED_DECLINED]: {
      label: `${t('REJECTED_BY_WAREHOUSE')}`,
      color: palette.error.dark,
    },
    [STATUS.WAREHOUSE_CHECKED_CHANGE]: {
      label: `${t('MODIFIED_BY_WAREHOUSE')}`,
      color: palette.warning.dark,
    },
    [STATUS.USER_ACCEPTED]: {
      label: `${t('ACCEPTED_BY_DISTRIBUTOR')}`,
      color: palette.warning.dark,
    },
    [STATUS.USER_DECLINED]: {
      label: `${t('CANCELED_BY_DISTRIBUTOR')}`,
      color: palette.error.dark,
    },
    [STATUS.READY]: {
      label: `${t('READY_TO_PICKUP')}`,
      color: palette.warning.dark,
    },
    [STATUS.READY_FOR_DELIVERY]: {
      label: `${t('READY_TO_DELIVERY')}`,
      color: palette.warning.dark,
    },
    [STATUS.READY_FOR_PICKUP]: {
      label: `${t('SELF_PICKUP')}`,
      color: palette.warning.dark,
    },
    [STATUS.DELIVERY_IN_PROGRESS]: {
      label: `${t('IS_DELIVERED')}`,
      color: palette.warning.dark,
    },
    [STATUS.DONE]: {
      label: `${t('DONE')}`,
      color: palette.success.dark,
    },
  }

  return (
      ORDER_STATUSES
  );

}

export function getStatusParams(status) {
  const ORDER_STATUSES = MyComponent();
  if (!status || !ORDER_STATUSES[status]) {
    return ORDER_STATUSES.new;
  }
  return ORDER_STATUSES[status];
}
