import { filter } from 'lodash';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination, Tabs, Tab, CircularProgress, Grid,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import {getNewPartners} from "../utils/apiCalls";
import {useTranslation} from "../providers";
import {descendingComparator} from "../utils/descendingComparator";

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//     if (b[orderBy] < a[orderBy]) {
//         return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//         return 1;
//     }
//     return 0;
// }

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function NewPartners() {
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('full_name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [newPartners, setNewPartners] = useState([]);
    const [newPartnersWarehouseAssigned, setNewPartnersWarehouseAssigned] = useState([]);
    const [newPartnersRejected, setNewPartnersRejected] = useState([]);
    const [newPartnersSendedToOneC, setNewPartnersSendedToOneC] = useState([]);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const TABLE_HEAD = [
        { id: 'id', label: `${t('ID')}`, alignRight: false },
        { id: 'full_name', label: `${t('FULL_NAME')}`, alignRight: false },
        { id: 'phone', label: `${t('PHONE')}`, alignRight: false },
        { id: 'email', label: `${t('EMAIL')}`, alignRight: false },
        { id: 'registration_date', label: `${t('CREATED_DATE')}`, alignRight: false },
        { id: '' },
    ];

    useEffect(() => {
        getNewPartners()
            .then((response) => {
                const data = response.data;
                setNewPartners(data?.warehouseAssigned);
                setNewPartnersWarehouseAssigned(data?.warehouseAssigned);
                setNewPartnersRejected(data?.rejected);
                setNewPartnersSendedToOneC(data?.sendedToOneC);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

    }, []);

    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };

    const warehouseAssignedTab = () => {
        setNewPartners([...newPartnersWarehouseAssigned])
    }
    const rejectedTab = () => {
        setNewPartners([...newPartnersRejected])
    }
    const sendedToOneCTab = () => {
        setNewPartners([...newPartnersSendedToOneC])
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newPartners.length) : 0;

    const filteredUsers = applySortFilter(newPartners, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    return (
        <Page title="Мои новички">
            {!newPartners ? (
                <CircularProgress />
            ) : (
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} className={'goods_no_mob'}>
                    <Typography variant="h4" gutterBottom>
                        {t('NEWERS')}
                    </Typography>
                    <Tabs value={currentTabIndex} onChange={handleTabChange}>
                        <Tab label={t('WAREHOUSEASSIGNED')} onClick={warehouseAssignedTab} value={0}/>
                        <Tab label={t('REJECTED_NEW')} onClick={rejectedTab} value={1}/>
                        <Tab label={t('SENDEDTOONEC')} onClick={sendedToOneCTab} value={2}/>
                    </Tabs>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} className={'goods_no_desktop'}>
                    <Typography variant="h4" gutterBottom>
                        {t('NEWERS')}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} className={'goods_no_desktop'}>
                    <Tabs value={currentTabIndex} onChange={handleTabChange}>
                        <Tab label={t('WAREHOUSEASSIGNED')} onClick={warehouseAssignedTab} value={0} style={{padding:'0 1px 0 1px'}}/>
                        <Tab label={t('REJECTED_NEW')} onClick={rejectedTab} value={1} style={{padding:'0 10px 0 1px'}}/>
                        <Tab label={t('SENDEDTOONEC')} onClick={sendedToOneCTab} value={2} style={{padding:'0'}}/>
                    </Tabs>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={newPartners.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { id, full_name, email, phone, registration_date } = row;
                                        const isItemSelected = selected.indexOf(full_name) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                onClick={() => navigate(`${row.id}`)}
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell>&nbsp;
                                                </TableCell>
                                                <TableCell align="left">{id}</TableCell>
                                                <TableCell align="left">{full_name}</TableCell>
                                                <TableCell align="left">{email}</TableCell>
                                                <TableCell align="left">{phone}</TableCell>
                                                <TableCell align="left">{registration_date !== null && registration_date.split("T")[0]}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                               <div>No results</div>
                                           </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={newPartners.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
                )}
        </Page>
    );
}
