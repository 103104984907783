import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as Yup from "yup";
import { useFormik } from "formik";
import { postWarehouseAction } from "../../utils/ClientApi";
import ActionsWrapper from "./ActionsWrapper";
import OrderTable from "./OrderTable";
import {useTranslation} from "../../providers";

const validationSchemaReason = Yup.object({
  reason: Yup.string().required("Обязательное поле"),
});

// const validationSchemaPartial = Yup.object({
//   reason: Yup.string().required("Обязательное поле"),
// });

export default function WhPendingForm({ children, order, actionWrapper }) {
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [openPartialModal, setOpenPartialModal] = useState(false);
  const {t} = useTranslation();

  const {
    handleChange: handleChangeReason,
    submitForm: submitFormReason,
    errors: errorsReason,
  } = useFormik({
    initialValues: { reason: "" },
    validationSchema: validationSchemaReason,
    onSubmit: async (values) => {
      actionWrapper(() =>
        postWarehouseAction(order._id, "cancel", { reason: values.reason })
      );
      setOpenReasonModal(false);
    },
  });

  const {
    submitForm: submitFormPartial,
    values: partialOrder,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      items: order.items,
      // items_count: order.items_count,
      // total: order.orders_total,
    },
    onSubmit: async (values) => {
      actionWrapper(() =>
        postWarehouseAction(order._id, "checked-change", values)
      );
      setOpenReasonModal(false);
    },
  });

  const handleClickOpenReasonModal = () => {
    setOpenReasonModal(true);
  };

  const handleCloseReasonModal = () => {
    setOpenReasonModal(false);
  };

  const handleClickOpenPartialModal = () => {
    setOpenPartialModal(true);
  };

  const handleClosePartialModal = () => {
    setOpenPartialModal(false);
    resetForm();
  };

  const actions = [
    {
      label: `${t('CONFIRMED_AVAILABILITY')}`,
      handler: (orderId) => postWarehouseAction(orderId, "checked-accepted"),
      variant: "contained",
    },
    {
      label: `${t('CONFIRMED_PARTIAL_AVAILABILITY')}`,
      // handler: (orderId) => postWarehouseAction(orderId, "checked-change"),
      handler: handleClickOpenPartialModal,
      variant: "contained",
    },
    {
      label: `${t('REJECTED')}`,
      handler: handleClickOpenReasonModal,
    },
  ];

  return (
    <div>
      <Dialog open={openReasonModal} onClose={handleCloseReasonModal}>
        <DialogTitle>{t('REJECT_THE_ORDER')}</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Причина"
            name="reason"
            type="text"
            fullWidth
            variant="standard"
            error={!!errorsReason.reason}
            onChange={handleChangeReason}
          />
          <FormHelperText error>{errorsReason.reason}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReasonModal}>{t('CANCEL')}</Button>
          <Button onClick={submitFormReason}>{t('REJECTED')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openPartialModal} onClose={handleClosePartialModal}>
        <DialogTitle>{t('EDITING_THE_ORDER')}</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>
          <OrderTable
            editable
            order={order}
            partialOrder={partialOrder}
            onChange={(value) => setFieldValue("items", value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePartialModal}>{t('CANCEL')}</Button>
          <Button onClick={submitFormPartial}>{t('CONFIRMED')}</Button>
        </DialogActions>
      </Dialog>
      <ActionsWrapper
        actions={actions}
        orderId={order._id}
        actionWrapper={actionWrapper}
      />
    </div>
  );
}
