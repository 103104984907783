import ActionsWrapper from "./ActionsWrapper";
import { postWarehouseAction } from "../../utils/ClientApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Paper,
} from "@mui/material";
import {useTranslation} from "../../providers";

const validationSchema = Yup.object({
  warehouse_total: Yup.number()
    .required("Обязательное поле")
    .positive("Сумма должна быть больше 0"),
  discount: Yup.number("Введите число"),
  points: Yup.number("Введите число").required("Обязательное поле"),
  payment_link: Yup.string().when("paid_online", (paid_online) => {
    return paid_online
      ? Yup.string().required("Обязательное поле")
      : Yup.string().url("Введите корректную ссылку");
  }),
});

export default function WhMakeOrderForm({ actionWrapper, order }) {
  const {t} = useTranslation();
  const { handleChange, submitForm, errors, values } = useFormik({
    initialValues: {
      warehouse_total: 0,
      discount: 0,
      paid_online: false,
      points: 0,
      payment_link: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("values trigger:", values);
      actionWrapper(() => postWarehouseAction(order._id, "ready", values));
    },
  });

  const actions = [
    {
      label: t("PLACE_ORDER"),
      handler: () => {
        submitForm();
      },
      variant: "contained",
    },
  ];

  return (
    <div>
      <Paper elevation={3} sx={{ p: 4 }}>
        <TextField
          autoFocus
          margin="dense"
          label={t('FINAL_COST')}
          name="warehouse_total"
          type="number"
          fullWidth
          variant="standard"
          error={!!errors.warehouse_total}
          onChange={handleChange}
        />
        <FormHelperText error>{errors.warehouse_total}</FormHelperText>
        <TextField
          margin="dense"
          label={t('DISCOUNT')}
          name="discount"
          type="number"
          fullWidth
          variant="standard"
          error={!!errors.discount}
          onChange={handleChange}
        />
        <FormHelperText error>{errors.discount}</FormHelperText>
        <TextField
          margin="dense"
          label={t('POINTS')}
          name="points"
          type="number"
          fullWidth
          variant="standard"
          error={!!errors.points}
          onChange={handleChange}
        />
        <FormHelperText error>{errors.points}</FormHelperText>
        <FormControlLabel
          required
          control={
            <Checkbox
              name="paid_online"
              value={values.paid_online}
              onChange={handleChange}
            />
          }
          label={t('ONLINE_PAYMENT_OPTION')}
        />
        {values.paid_online && (
          <>
            <TextField
              margin="dense"
              label={t('PAYMENT_LINK')}
              name="payment_link"
              type="text"
              fullWidth
              variant="standard"
              error={!!errors.points}
              onChange={handleChange}
            />
            <FormHelperText error>{errors.payment_link}</FormHelperText>
          </>
        )}
      </Paper>
      <br />
      <ActionsWrapper actions={actions} orderId={order._id} />
    </div>
  );
}
