import {
  Grid,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import {useTranslation} from "../../../providers";

// ----------------------------------------------------------------------

export default function BlogPostCard({ promotion }) {
  const { user } = useSelector((state) => state.auth);
  const {t} = useTranslation();

  const doc = promotion.docs[user.country];

  return (
    <Grid item xs={12} sm={12} md={6} style={{marginBottom: "20px"}}>
      <a href={doc} className={"promotion_block_link"}>
        <img src={promotion.cover} alt={'perfume'} className={"promotion_img"} />
        <Button className={'button_prom'}>{t('LEARN_MORE')}</Button>
      </a>
    </Grid>
  );
}
