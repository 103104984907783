import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { default as MuiStepper } from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { STATUS } from "../../utils/constants";
import { GetStepperOrder } from "./stepper-config";
import { getTokenDetails } from "../../utils/apiCalls";
import ActionsWrapper from "./ActionsWrapper";

// ----------------------------------------------------------------------

export default function OrderStepper({ order, onAction }) {
  const [currentStatus, setCurrentStatus] = useState(order.status);
  const { wh: isWareHouse } = getTokenDetails() ?? {};

  useEffect(() => {
    setCurrentStatus(order.status);
  }, [order]);

  const stepperConfig = GetStepperOrder();

  const statusStepArr = stepperConfig[currentStatus]?.steps ?? [];
  if (currentStatus === STATUS.DONE && order.delivery_address) {
    statusStepArr.splice(
      statusStepArr.length - 1,
      0,
        ...[STATUS.READY_FOR_DELIVERY]
    );
  }

  const steps = statusStepArr.map((step) => stepperConfig[step]);

  return (
    <Box>
      <MuiStepper
        activeStep={statusStepArr.indexOf(currentStatus)}
        orientation="vertical"
      >
        {steps.map((stepObj) => {
          const step = stepObj[isWareHouse ? "wh" : "client"];
          const actions = step?.actions ?? [];
          const { component: Component = null } = step;
          const props = {};
          if (stepObj[STATUS.READY_FOR_DELIVERY]) {
            props.deliveryAddress = order.delivery_address;
          }

          return (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Box>
                  {Component ? (
                    <Component
                      order={order}
                      actionWrapper={onAction}
                      actions={actions}
                      orderId={order._id}
                      {...props}
                    />
                  ) : (
                    actions.length > 0 && (
                      <ActionsWrapper
                        actionWrapper={onAction}
                        actions={actions}
                        orderId={order._id}
                      />
                    )
                  )}
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </MuiStepper>
    </Box>
  );
}
