import React from "react";
import palette from "../theme/palette";
import Select from "react-select";
import { alpha } from "@mui/material";
import {useTranslation} from "../providers";

const MultipleSelect = ({
  defaultValue,
  options,
  name,
  label,
  value,
  onChange,
}) => {
    const {t} = useTranslation();
  return (
    <Select
      defaultValue={defaultValue}
      isMulti
      name={name}
      // placeholder="Выберите..."
      placeholder={t('CHOOSE')}
      options={options}
      getOptionLabel={(option) => option[label]}
      getOptionValue={(option) => option[value]}
      closeMenuOnSelect={false}
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        borderRadius: "8px",
        borderColor: palette.primary.main,
        colors: {
          ...theme.colors,
          primary: palette.primary.light,
          primary25: alpha(palette.primary.lighter, 0.5),
          primary50: alpha(palette.primary.light, 0.5),
        },
      })}
    />
  );
};

export default MultipleSelect;
