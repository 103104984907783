import React, { useEffect, useState } from "react";
import { getNetworkById } from "../utils/apiCalls";
import {
  Card,
  Stack,
  Container,
  Grid,
  Typography,
  TableCell,
  TableSortLabel,
} from "@mui/material";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { Link, useParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import palette from "../theme/palette";
import {useTranslation} from "../providers";

const defaultSortState = {
  lo: 0,
  go: 0,
  ngo: 0,
  olg: 0,
};

const MyNetworksItem = () => {
  const params = useParams();
  const [networkByIdItems, setNetworkByIdItems] = useState([]);
  const [networkById, setNetworkById] = useState("");
  const [sortState, setSortState] = useState(defaultSortState);

  const {t} = useTranslation();

  const handleSortClick = (sortType) => {
    setSortState((state) => ({
      ...defaultSortState,
      [sortType]: state[sortType] === 0 ? 1 : state[sortType] === 1 ? -1 : 0,
    }));
  };

  useEffect(() => {
    getNetworkById(params.id)
      .then((data) => {
        setNetworkById(data);
        setNetworkByIdItems(data?.network);
      })
      .catch((err_data) => {
        console.log("no data", err_data);
      });
  }, []);

  const sortedNetworkByIdItems = networkByIdItems.sort((a, b) => {
    if (sortState.lo !== 0) {
      return sortState.lo < 0 ? a.lo - b.lo : b.lo - a.lo;
    }
    if (sortState.go !== 0) {
      return sortState.go < 0 ? a.go - b.go : b.go - a.go;
    }
    if (sortState.ngo !== 0) {
      return sortState.ngo < 0 ? a.ngo - b.ngo : b.ngo - a.ngo;
    }
    if (sortState.olg !== 0) {
      return sortState.olg < 0 ? a.olg - b.olg : b.olg - a.olg;
    }
    return 0;
  });

  return (
    <Page>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          {/*<Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>*/}
          {/*  New User*/}
          {/*</Button>*/}
          <Typography variant="h6" gutterBottom>
            <Link to="/network" style={{ textDecoration: "none" }}>
              <div
                className={"col"}
                style={{
                  textAlign: "left",
                  paddingTop: "10px",
                  color: palette.primary.main,
                }}
              >
                <span
                  className={"material-symbols-outlined icon_arrow-back"}
                  style={{ color: palette.primary.main }}
                >
                  arrow_back
                </span>
                &nbsp; {t('MY_NETWORK')}
              </div>
            </Link>
          </Typography>
        </Stack>
        <br />
        <Card>
          <Grid container style={{ textAlign: "center" }}>
            <Grid item sm={2} xs={12}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item"}>{t('PRN')}</div>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item_parameter"}>
                    {networkById._id}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item"}>{t('FULL_NAME')}</div>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item_parameter"}>
                    {networkById.name}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item"}>{t('EMAIL')}</div>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item_parameter"}>
                    {networkById.email}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item"}>{t('PHONE')}</div>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item_parameter"}>
                    {networkById.phone}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={1} xs={12}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item"}>{t('PV')}</div>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item_parameter"}>
                    {networkById.lo}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={1} xs={12}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item"}>{t('GV')}</div>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item_parameter"}>
                    {networkById.go}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={1} xs={12}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item"}>{t('CGV')}</div>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item_parameter"}>
                    {networkById.ngo}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={1} xs={12}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item"}>{t('PGV')}</div>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <div className={"grid_network_item_parameter"}>
                    {networkById.olg}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <br />
        <br />
        <Typography mb={2} variant="h5">
          {t('NETWORK')} - {sortedNetworkByIdItems.length}
        </Typography>
        {sortedNetworkByIdItems.length > 0 && (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">{t('PRN')}</TableCell>
                      <TableCell align="left">{t('FULL_NAME')}</TableCell>
                      <TableCell align="left">{t('RANG')}</TableCell>
                      <TableCell
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleSortClick("lo")}
                        align="left"
                      >
                        <TableSortLabel
                          direction={sortState["lo"] === 1 ? "desc" : "asc"}
                          active={sortState["lo"]}
                        >
                          {t('PV')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleSortClick("go")}
                        align="left"
                      >
                        <TableSortLabel
                          direction={sortState["go"] === 1 ? "desc" : "asc"}
                          active={sortState["go"]}
                        >
                          {t('GV')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleSortClick("ngo")}
                        align="left"
                      >
                        <TableSortLabel
                          direction={sortState["ngo"] === 1 ? "desc" : "asc"}
                          active={sortState["ngo"]}
                        >
                          {t('CGV')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleSortClick("olg")}
                        align="left"
                      >
                        <TableSortLabel
                          direction={sortState["olg"] === 1 ? "desc" : "asc"}
                          active={sortState["olg"]}
                        >
                          {t('PGV')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">{t('SIGNING_DATE')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {networkByIdItems.map((item) => (
                      <TableRow
                        hover
                        key={item._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{item._id}</TableCell>
                        <TableCell align="left">{item.name}</TableCell>
                        <TableCell align="left">{item.rank}</TableCell>
                        <TableCell align="left">{item.lo}</TableCell>
                        <TableCell align="left">{item.go}</TableCell>
                        <TableCell align="left">{item.ngo}</TableCell>
                        <TableCell align="left">{item.olg}</TableCell>
                        <TableCell align="left">
                          {item.signed !== null && item.signed.split("T")[0]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        )}
      </Container>
    </Page>
  );
};
export default MyNetworksItem;
