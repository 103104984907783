import { Typography } from "@mui/material";
import ActionsWrapper from "./ActionsWrapper";
import {useTranslation} from "../../providers";

export default function ReadyForDelivery({ order, actionWrapper, actions }) {
  const {t} = useTranslation();
  if (!order?.delivery_address) {
    return null;
  }
  return (
    <div>
      <Typography sx={{ py: 4 }}>
        <b>{t('ADDRESS')}</b>: {order.delivery_address}
      </Typography>
      <ActionsWrapper
        actionWrapper={actionWrapper}
        actions={actions}
        orderId={order._id}
      />
    </div>
  );
}
